<itp-panel>

    <div class="row" *ngIf="!data">loading...</div>
    <div class="container-fluid" *ngIf="data">
        <form class="form-horizontal" name="ArchiveAppDocumentForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
                <h4 style="font-size: 1.5em; margin-bottom: 1em;" class="col-md-4"> Upload documents for '{{appName}}' </h4>
            </div>
            <div class="form-group">
                <label class="col-md-2 control-label">Name:</label>
                <div class="col-sm-6">
                    <input type="text" class="form-control col-md-10" [(ngModel)]="data.archiveAppDocument.name" name="name"
                        required>
                </div>
            </div>
            
            <div class="form-group">
                <label class="col-md-2 control-label">Period:</label>
                <div class="col-sm-6">
                    <kendo-datepicker [format]="'yyyy/MM/dd'" [(value)]="period"></kendo-datepicker>
                </div>
            </div>
            <div class="form-group">
                <label class="col-md-2 control-label">Tags:</label>
                <div class="col-sm-6">
                    <kendo-multiselect [data]="data.tags" [value]="SelectedTags" class="form-control col-md-10"
                        name="tags" [allowCustom]="true" (valueChange)="onSizeChange($event)">
                    </kendo-multiselect>
                </div>
            </div>
            <div class="form-group">
                <label class="col-md-2 control-label">File</label>
                <div class="col-sm-6">
                    <file-upload [uploadSaveUrl]="uploadSaveUrl" (fileUploaded)="isUploaded($event)"></file-upload>
                </div>
            </div>

            <div class="form-group">
                <label class="col-md-2 control-label"></label>
                <div class="col-sm-6" style="display:inline">
                    <button type="submit" style="display:inline" class="btn-deloitte btn">Submit</button>
                    <div *ngIf="loading" style="display:inline;margin:10px"> 
                        <i class="fa fa-spinner fa-spin"></i>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <list-archive-apps [appId]="appId" (onAppNameLoaded)="loadAppName($event)" (onEditClick)="editArchiveAppDocument($event)"></list-archive-apps>


</itp-panel>