﻿import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { GridModule } from '@progress/kendo-angular-grid';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';

import { SharedModule } from '../../shared/shared.module';
import { routing } from "./vat-countries.routing";

import { ListVatCountriesComponent } from "./components/list-vat-countries/list-vat-countries.component";
import { VatCountriesOverviewComponent } from "./components/overview/overview.component";
import { CreateVatCountryComponent } from "./components/create-vat-country/create-vat-country.component";
import { EditVatCountryComponent } from "./components/edit-vat-country/edit-vat-country.component";

@NgModule({
    declarations: [
        ListVatCountriesComponent,
        VatCountriesOverviewComponent,
        CreateVatCountryComponent,
        EditVatCountryComponent
    ],
    imports: [
        routing,
        FormsModule,
        SharedModule,
        GridModule,
        DialogModule,
        DropDownsModule,
        CommonModule
    ]
})
export class VATCountriesModule { }