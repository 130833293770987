﻿import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'itp-panel',
    templateUrl: './panel.component.html'
})
export class ItpPanel implements OnInit {
    @Input() heading: string;
    @Input() element: string;

    ngOnInit(): void {
        if (!this.element && this.heading) {
            this.element = this.heading;
        }
    }
}