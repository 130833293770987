import { Component, OnInit, Input, Output, TemplateRef, EventEmitter } from '@angular/core';

@Component({
  selector: 'file-download',
  templateUrl: './file-download.component.html',
  styleUrls: ['./file-download.component.css']
})
export class FileDownloadComponent {
  @Input() file: string;

  @Input() showDownloadButton: boolean = true;
  @Input() fileType: string;
  @Input() fileName: string;
  @Input() textTemplate: TemplateRef<any>;

  @Output() onFileDownload: EventEmitter<any> = new EventEmitter<any>();

  onClick() {
    this.onFileDownload.emit(this.file);
  }

}
