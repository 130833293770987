﻿import { Component, OnInit } from '@angular/core';

import { DataService } from "../../../../shared/services/data.service";
import { UrlPath } from "../../../../shared/common/url-path";
import { AppTypeModel } from "../../../../shared/models/apps/app-type.model";
import { NewAppModel, AppCreationResult } from "../../../../shared/models/apps/new-app.model";
import { retry } from 'rxjs/operators';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FileRestrictions } from '@progress/kendo-angular-upload';

@Component({
    selector: 'add-new-app',
    templateUrl: './add-new-app.component.html',
    providers: [DataService],
    styleUrls: ['../list-apps/list-apps.component.scss']
})
export class AppNewAppComponent implements OnInit {
    roles: any;
    selectedAppType: AppTypeModel;
    appName: string;
    appDescription: string;
    appContent: string;
    appUrl: string;
    appTypes: AppTypeModel[];
    archiveAppId: string;
    uploadSaveUrl:string;
    alowedTypes: FileRestrictions = {
        allowedExtensions: ['.jpg', '.png', '.jpeg']
      };
    fileContent: any;

    constructor(private readonly dataService: DataService) { }

    ngOnInit() {         
        this.dataService.get<any>(UrlPath.roles_getRoles()).subscribe((data: any) => {
        this.roles = data;          
        console.log(this.roles);
    });
        this.getAppTypes();
    }

    getAppTypes() {
        this.dataService.get<AppTypeModel[]>(UrlPath.appTypeApi).subscribe((data: AppTypeModel[]) => {
            this.appTypes = data;
            this.uploadSaveUrl = UrlPath.uploadFile;
           
            if (this.appTypes && this.appTypes.length) {
                var archiveApp = this.appTypes.filter(t => t.name === "Archive App")[0];
                this.archiveAppId = archiveApp ? archiveApp.id : '';
                this.selectedAppType = this.appTypes[0];
            }
        });
    }

    appForm = new FormGroup({
        appType: new FormControl('', Validators.required),
        name: new FormControl('', Validators.required),
        description: new FormControl('', Validators.required),
        appIsVisible: new FormControl(true),
        appIsOffered: new FormControl(),
        url: new FormControl(),
        alternateUrl: new FormControl(),
        thumbnailImage: new FormControl(),
        appIsWhiteRoles: new FormControl(),
        appIsGreyRoles: new FormControl()
    });

    createApp() {
        if (!this.selectedAppType) return;
        console.log(this.fileContent);
        var model = <NewAppModel>{
            AppTypeId: this.appForm.controls['appType'].value,
            Description: this.appForm.controls['description'].value,
            Name: this.appForm.controls['name'].value,
            Url: this.appForm.controls['url'].value,
            AlternateUrl: this.appForm.controls['alternateUrl'].value,
            IsOffered: this.appForm.controls['appIsOffered'].value,
            IsVisible: this.appForm.controls['appIsVisible'].value,
            IsWhiteRoles: this.appForm.controls['appIsWhiteRoles'].value.join(";"),
            IsGreyRoles: this.appForm.controls['appIsGreyRoles'].value.join(";")
        };
        if(this.fileContent != null){
            model.ThumbnailImage = this.fileContent.content;
        }

        this.dataService.post<AppCreationResult>(UrlPath.admin_appApi_createApp, model).subscribe((result: AppCreationResult) => {
            if (this.appForm.controls['appType'].value === this.archiveAppId) {
                window.location.href = UrlPath.editArchiveAppDocument(result.appId);
            }
            else {
                window.location.href = UrlPath.admin_apps;
            }
        });
    }

    isUploaded(uploadResonse: any) {
        this.fileContent = uploadResonse.fileEntity;

    }
}