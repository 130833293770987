import { Injectable } from "@angular/core";

import { CurrentUserModel } from "../models/current-user";
import { DataService } from "./data.service";
import { UrlPath } from "../common/url-path";
import { Observable } from 'rxjs';
import { MenuItemModel } from '../models/menu-item';
import { refCount, publishReplay } from 'rxjs/operators';

//TODO implement this along with security
@Injectable({
    providedIn: 'root'
})
export class MenuSharedService {
    constructor(private readonly dataService: DataService) { }
    menus: Observable<MenuItemModel[]>
    getMenus(): Observable<MenuItemModel[]> {
        console.log('inside service');
        if (!this.menus){
        this.menus = this.dataService.get<MenuItemModel[]>(UrlPath.getMenus("")).pipe(publishReplay(1), refCount());
        }
        return this.menus;
    }

    clearCache() {
        this.menus = null;
    }
}