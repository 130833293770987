import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../../../../shared/services/data.service';
import { UrlPath } from '../../../../shared/common/url-path';
import { ListArchiveAppDocumentsComponent } from '../list-archive-apps/list-archive-apps.component';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FileUploadComponent } from '../../../../shared/components/file-upload/file-upload.component';

@Component({
    selector: 'itp-archive-app',
    templateUrl: './archive-app.component.html',
    styles: []
})
export class ArchiveAppDocumentComponent implements OnInit {
    appId: string;
    loading: boolean;
    data: any;
    @ViewChild(ListArchiveAppDocumentsComponent) ArchiveAppDocumentList: ListArchiveAppDocumentsComponent;
    @ViewChild(FileUploadComponent) fileUploadComponent: FileUploadComponent;
    fileContent: any;
    SelectedTags: any;
    SelectedTopics: any;
    appName:string;
    existingTags: string;
    constructor(private readonly route: ActivatedRoute, readonly dataService: DataService) { }
    public period: Date = new Date();
    uploadSaveUrl: any;
    ngOnInit() {
        if (this.route.snapshot.paramMap.get('appId') == '' || this.route.snapshot.paramMap.get('appId') == "new") {
            this.appId = '';
        }
        else {
            this.appId = this.route.snapshot.paramMap.get('appId');
        }
        
        let key = this.appId;
        this.uploadSaveUrl = UrlPath.uploadFile;
        this.loadDocument('new');
        this.ArchiveAppDocumentList.LoadArchiveAppDocuments(this.appId);
    }

    public onSizeChange(value: any) {
        this.SelectedTags = value;
    }



    public loadDocument(key: string) {
        this.dataService.get
            (UrlPath.getArchiveAppDocumentDetails(key)).subscribe(data => {
                this.data = data;
                this.ArchiveAppDocumentList.canModifyPermissions = false;

            });
    }

    public loadAppName(appName: string) {
        this.appName = appName;
    }

    public onSubmit() {
        this.loading = true;
        let args: any = {
            tags: this.SelectedTags,
            ArchiveAppDocumentId: this.data.archiveAppDocument.archiveAppDocumentId,
            Name: this.data.archiveAppDocument.name,
            ArchiveAppId: this.appId,
            Type: this.data.archiveAppDocument.type,
            Period: this.period

        };
        if (this.fileContent) {
            args.FileContent = this.fileContent.content;
            args.FileName = this.fileContent.name;
        }
        this.data.archiveAppDocument = {};
        this.SelectedTags = [];
        this.fileUploadComponent.clear();
        
        this.dataService.post(UrlPath.ManageArchiveAppDocuments(), args).subscribe(
            data => {
                this.loading = false;
                this.ArchiveAppDocumentList.LoadArchiveAppDocuments(this.appId);
            }
        );

    }

    editArchiveAppDocument(editArchiveAppDocument: any) {
        
        if (this.data && this.data.archiveAppDocument) {
            this.data.archiveAppDocument.archiveAppDocumentId = editArchiveAppDocument.id;
            this.data.archiveAppDocument.name = editArchiveAppDocument.name;
            this.period = new Date(editArchiveAppDocument.period);
            let existingTags = editArchiveAppDocument.tags.split(';');
            this.SelectedTags = this.data.tags.filter((value: any) => -1 !== existingTags.indexOf(value))
        }
    }



    isUploaded(uploadResonse: any) {
        
        this.fileContent = uploadResonse.fileEntity;

    }
}
