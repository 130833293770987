import { AlertService } from '../services/alert.service';
import { FileService } from '../services/file.service';
import { ActiveToast } from 'ngx-toastr';
import { DataService } from '../services/data.service';
import { UrlPath } from '../common/url-path';

export class FileHelper {
    static async convertFileToBase64String(file: File) : Promise<string>{
        return new Promise<string>((resolve, reject) => {
            let fileReader: FileReader = new FileReader();
            fileReader.onloadend = (e) => {
                resolve(fileReader.result as string);
            }
            fileReader.readAsDataURL(file);
        });
    }

    static convertBase64ToIntArray(b64Data: string): Uint8Array {
        let splitIndex = b64Data.indexOf(",");
        b64Data = b64Data.substring(splitIndex === -1 ? 0 : splitIndex + 1);

        let sliceSize = 512;

        let byteCharacters = atob(b64Data);
        let byteArrays: Array<Uint8Array> = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            let slice = byteCharacters.slice(offset, offset + sliceSize);

            let byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            let byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }
        return byteArrays[0];

    }

    static convertBase64ToBlob(b64Data: string): Blob {
        let splitIndex = b64Data.indexOf(",");
        b64Data = b64Data.substring(splitIndex === -1 ? 0 : splitIndex + 1);

        let sliceSize = 512;
        
        let byteCharacters = atob(b64Data);
        let byteArrays: Array<Uint8Array> = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            let slice = byteCharacters.slice(offset, offset + sliceSize);

            let byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            let byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        let blob = new Blob(byteArrays, { type: '' });
        return blob;
    }

    //implementation based on https://stackoverflow.com/questions/1106377/detect-when-browser-receives-file-download
    static async downloadFile(blobId: string, fileName: string, fileService: DataService, alertService: AlertService) {
        if(alertService != null){
            alertService.info("Download started", `File ${fileName} download has started, please wait while it finishes.`);
        }
        window.open(UrlPath.getFileContent(blobId), '_blank');
    }

    static openWindowForDownload(blob: Blob, fileName: string) {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileName);
        }
        else {
            let url = window.URL.createObjectURL(blob);
            let anchor = document.createElement('a');
            anchor.href = url;
            anchor.target = '_blank';
            anchor.download = fileName;
            document.body.appendChild(anchor);
            anchor.click();
        }
    } 

    static getCookie(name: string) {
        var parts = document.cookie.split(name + "=");
        if (parts.length == 2) return parts.pop().split(";").shift();
    }

    static expireCookie(cName: string) {
        document.cookie = encodeURIComponent(cName) + "=deleted; expires=" + new Date( 0 ).toUTCString();
    }

    static waitForCookie(downloadToken: string, activeToast: ActiveToast<any>) {
        let downloadTimer = window.setInterval( function() {
            let token = FileHelper.getCookie(downloadToken);
    
            if(token == downloadToken){
                activeToast.toastRef.manualClose();
                FileHelper.expireCookie(downloadToken);
                window.clearInterval(downloadTimer);
            }
    
        }, 1000 );
    }
}