﻿import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { DataService } from "../../../../shared/services/data.service";
import { UrlPath } from "../../../../shared/common/url-path";
import { EditViewModel } from "../../../../shared/models/clients/edit-view-model.model";

@Component({
    selector: 'edit-clients',
    templateUrl: './edit-client.component.html',
    providers: [DataService]
})
export class EditClientComponent implements OnInit {
    model: EditViewModel;
    id: string;
    loading: boolean;

    constructor(private readonly dataService: DataService,
        private readonly route: ActivatedRoute) { }

    ngOnInit() {
        this.id = this.route.snapshot.paramMap.get('id');
        this.get();
    }

    
    get() {
        this.loading = true;
        /*
        this.dataService.get<EditViewModel>(UrlPath.clientsApi_getEditViewModel(this.id)).subscribe(model => {

        });
        */
    }

    save() {
        this.dataService.put<boolean>(UrlPath.clientsApi_edit, this.model.Model).subscribe((success: boolean) => {
            if (success) {
                window.location.href = UrlPath.clients;
            }
        });
    }
}