﻿import { Component, OnInit } from "@angular/core";

import { UrlPath } from "../shared/common/url-path";
import { DataService } from "../shared/services/data.service";

@Component({
    selector: "itp-internal-login",
    templateUrl: "./internal-login.component.html"
})
export class InternalLoginComponent implements OnInit {
    //email: string;

    constructor(private readonly dataService: DataService) { }

    ngOnInit(): void {
        //this.activatedRoute.data.subscribe(data => {
        //    this.email = data.email;
        //});
    }

    internalLogin(mail: string): void {
        this.dataService.get<any>(UrlPath.internalLoginGetUser(mail)).subscribe(_ => {
            window.location.href = '';
        });
    }
}