import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { UrlPath } from "../common/url-path";

@Injectable()
export class FileService {

    constructor(private http: HttpClient) { }
    getFile(blobRepositKey: string): Observable<any> {
        let url = UrlPath.getFileContent(blobRepositKey);
        return this.http.get(url);
    }
}