﻿import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { GridModule } from '@progress/kendo-angular-grid';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';

import { SharedModule } from '../../shared/shared.module';
import { routing } from "./legal-entities.routing";

import { LegalEntitiesOverviewComponent } from "./components/overview/overview.component";
import { ListLegalEntitiesComponent } from "./components/list-legal-entities/list-legal-entities.component";
import { CreateLegalEntityComponent } from "./components/create-legal-entity/create-legal-entity.component";
import { EditLegalEntityComponent } from "./components/edit-legal-entity/edit-legal-entity.component";

@NgModule({
    declarations: [
        LegalEntitiesOverviewComponent,
        ListLegalEntitiesComponent,
        CreateLegalEntityComponent,
        EditLegalEntityComponent
    ],
    imports: [
        routing,
        FormsModule,
        SharedModule,
        GridModule,
        DialogModule,
        DropDownsModule,
        CommonModule
    ]
})
export class LegalEntitiesModule { }