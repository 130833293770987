export class SalesType {
    static b2b = new SalesType("Business-to-business", 0);
    static b2c = new SalesType("Business-to-customer", 1);
    static b2bAndB2c = new SalesType("B2B and B2C", 2);

    readonly name: string;
    readonly id: number;

    constructor(name: string, id: number) { this.name = name; this.id = id}

    static readonly all: SalesType[] = [SalesType.b2b, SalesType.b2c, SalesType.b2bAndB2c];

    static tryParse(name: string): SalesType {
        return SalesType.all.find(x => x.name === name);
    }
}