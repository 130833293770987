﻿import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { GridModule } from '@progress/kendo-angular-grid';
import { SharedModule } from '../shared/shared.module';

import { IntlModule } from '@progress/kendo-angular-intl';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { LegalEntityDetailsComponent } from './components/legal-entity-details/legal-entity-details.component';
import { LegalEntityOverviewComponent } from './components/legal-entity-overview/legal-entity-overview.component';
import { routing } from './legal-entity.routing';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import {InputSwitchModule} from 'primeng/inputswitch';


@NgModule({
    declarations: [
        LegalEntityOverviewComponent,
        LegalEntityDetailsComponent
    ],
    imports: [
        routing,
        FormsModule,
        SharedModule,
        GridModule,
        CommonModule,
        IntlModule,
        DateInputsModule,
        ReactiveFormsModule,
        DropDownsModule,
        InputSwitchModule
    ]
})
export class LegalEntityModule { }