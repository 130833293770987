﻿<kendo-grid [data]="dataGridService.data" [pageSize]="dataGridService.state.take" [skip]="dataGridService.state.skip"
    [sort]="dataGridService.state.sort" [sortable]="true" [pageable]="dataGridService.pageable" [groupable]="false"
    [scrollable]="'scrollable'" [filter]="dataGridService.state.filter" filterable="menu" [columnMenu]="true"
    [resizable]="true" [reorderable]="true" [loading]="false"
    (dataStateChange)="dataGridService.dataStateChange($event)" (edit)="editHandler($event)" (add)="addHandler()"
    (remove)="removeHandler($event.dataItem)">

    <ng-template kendoGridToolbarTemplate>
        <a class="btn btn-primary" [attr.href]="addNewAppLink()">Add new</a>
    </ng-template>

    <kendo-grid-column field="ClientName" title="Client Name">
        <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.ClientName}}</ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="Name" title="Legal Entity Name">
        <ng-template kendoGridCellTemplate let-dataItem>
            <a [attr.href]="appLink(dataItem)">{{dataItem.Name}}</a>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-command-column title="">
        <ng-template kendoGridCellTemplate let-dataItem>
            <a class="btn btn-primary" [attr.href]="editAppLink(dataItem)">Edit</a>
            <a class="btn btn-primary" (click)="remove(dataItem)">Delete</a>
        </ng-template>
    </kendo-grid-command-column>
</kendo-grid>