﻿import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { routing } from "./surveys.routing";
import { SurveyTitlesComponent } from './components/survey-titles/survey-titles.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GridModule } from '@progress/kendo-angular-grid';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { IntlModule } from '@progress/kendo-angular-intl';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { CommonModule } from '@angular/common';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { AddTitlesStructure } from './components/survey-titles/add-titles-structure/add-titles-structure.component';
@NgModule({
    declarations: [
        SurveyTitlesComponent,
        AddTitlesStructure
        ],
    imports: [
        routing,
        SharedModule,
        FormsModule,
        SharedModule,
        GridModule,
        DialogModule,
        DropDownsModule,   
        IntlModule,
        DateInputsModule,
        CommonModule,
        ReactiveFormsModule,
        InputsModule
    ],

})
export class SurveysModule { }