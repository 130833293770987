import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'remove-item',
  templateUrl: './remove-item.component.html',
  styles: []
})
export class RemoveItemComponent {

  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() delete: EventEmitter<any> = new EventEmitter();
  @Input() public active: boolean = false;
  public entity: any;
  public entityType: string;
  public entityName: string;


  public setActive(state: boolean) {
      this.active = state;
  }

  public onDelete(e: any): void {
      e.preventDefault();
      this.setActive(false);
      this.delete.emit(this.entity);
  }

  public onCancel(e: any): void {
      e.preventDefault();
      this.closeForm();
  }

  private closeForm(): void {
      this.setActive(false);
      this.cancel.emit();
  }
}
