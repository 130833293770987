﻿<itp-panel>

    <div class="container-fluid">
        <div class="row pull-right">
            <button class="btn btn-primary" (click)="save()">Save</button>
        </div>
        <div class="row" *ngIf="!entity">loading...</div>
        <form class="form-horizontal" name="form" *ngIf="entity">
            <div class="form-group">
                <label class="col-md-2 control-label" for="ClientName">Client Name</label>
                <div class="col-sm-6">
                    <input id="ClientName" disabled class="form-control" [(ngModel)]="entity.ClientName"
                        [ngModelOptions]="{standalone: true}" />
                </div>
            </div>

            <div class="form-group">
                <label class="col-md-2 control-label" for="Name">Name</label>
                <div class="col-sm-6">
                    <input id="Name" required class="form-control" [(ngModel)]="entity.Name"
                        [ngModelOptions]="{standalone: true}" />
                </div>
            </div>

        </form>
    </div>


</itp-panel>