﻿import { Injectable } from '@angular/core';
import { ToastrService, ToastContainerDirective } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class AlertService {
    static singletonInstance: AlertService;

    constructor(private messageService: ToastrService) {
    }

    setOverlayContainer(toastContainer: ToastContainerDirective) {
        this.messageService.overlayContainer = toastContainer;
    }

    success(title: string, message: string, length: number = 4000) {
        this.messageService.success(message, title, { timeOut: length, onActivateTick: true });
    }

    error(title: string, message: string, length: number = 20000) {
        this.messageService.error(message, title, { timeOut: length, extendedTimeOut: 0, onActivateTick: true });
    }

    info(title: string, message: string, length: number = 4000) {
        this.messageService.info(message, title, { timeOut: length, extendedTimeOut: 5000, onActivateTick: true });
    }

    warning(title: string, message: string, length: number = 10000) {
        this.messageService.warning(message, title, { timeOut: length, extendedTimeOut: 0, onActivateTick: true });
    }

    // Helper method for errorHandling
    extractAndDisplayError(possibleErrorResult: any) {
        if (!possibleErrorResult) return; // No error passed, display nada;

        //If error has rejection property then this is an error coming from a promise(or await) and we need to get the error message from the rejection property
        if (possibleErrorResult.rejection) possibleErrorResult = possibleErrorResult.rejection;
        let message = possibleErrorResult.error
            ? this.isJsonString(possibleErrorResult.error.message) // is ApiFilterException
                ? this.isJsonString(JSON.parse(possibleErrorResult.error.message).message)
                    ? JSON.parse(JSON.parse(possibleErrorResult.error.message).message)
                    : JSON.parse(possibleErrorResult.error.message).message
                : possibleErrorResult.error.message
                    ? possibleErrorResult.error.message
                    : possibleErrorResult.message
            : possibleErrorResult.toString();

        if (Array.isArray(message)) (message as any[]).forEach(m => this.warning('Possible failure', m));
        else if (message.indexOf("ExpressionChangedAfterItHasBeenCheckedError") >= 0) return; //No need to show ExpressionChangedAfterItHasBeenCheckedError
        else this.warning('Possible failure', message);
    }
    // Helper method for checking whether a string is valid JSON
    isJsonString(str: string) {
        try { JSON.parse(str); } catch (e) { return false; }
        return true;
    }

    clear(): void {
        this.messageService.clear();
    }
}