﻿<kendo-grid [data]="data" [scrollable]="'scrollable'" [resizable]="true"
(dataStateChange)="dataGridService.dataStateChange($event)" 
(edit)="editHandler($event)" (add)="addHandler()" (remove)="removeHandler($event.dataItem)">
<ng-template kendoGridToolbarTemplate>    
    <a class="btn btn-deloitte" (click)="addNewIndustry()">Add new</a>
</ng-template>
<kendo-grid-column field="Name" title="Name" width="200">
    <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem.name}}
    </ng-template>
</kendo-grid-column>
<kendo-grid-command-column title="" width="250">
    <ng-template kendoGridCellTemplate let-dataItem>
        <a class="btn btn-deloitte" (click)="ediIndustry(dataItem)"> Edit</a>
    </ng-template>
</kendo-grid-command-column>
</kendo-grid>