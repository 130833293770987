﻿import { Component } from '@angular/core';


@Component({
    templateUrl: './incorrect-organization.component.html'
})
export class IncorrectOrganizationComponent {
   
    constructor() { }  
}

