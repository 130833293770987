﻿<itp-panel>

    <div class="container-fluid">
        <div class="row pull-right">
            <button class="btn btn-primary" (click)="create()">Create</button>
        </div>
        <form class="form-horizontal" name="form">
            <div class="form-group">
                <label class="col-md-2 control-label" for="Name">Name</label>
                <div class="col-sm-6">
                    <input id="Name" required class="form-control" [(ngModel)]="name"
                        [ngModelOptions]="{standalone: true}" />
                </div>
            </div>

        </form>
    </div>


</itp-panel>