﻿<itp-panel>
    <div style="margin-right: 3em; margin-left: 2.2em;">
        <h4 style="font-size: 1.5em; margin-bottom: 1em;">Admin - Add app</h4>


        <div class="card col-7">
            <div class="card-body p-4">
                <div class="form-group">
                    <h3 class="card-title mb-4" style="font-size: 1.2rem;">Add App</h3>
                </div>

                <form [formGroup]="appForm">
                    <div class="form-group">
                        <label>Type:</label>
                        <select class="form-control" id="appType" formControlName="appType">
                            <option *ngFor="let appType of appTypes" [value]="appType?.id">{{appType?.name}}</option>
                        </select>


                    </div>

                    <div class="form-group">
                        <label>Name:</label>
                        <input class="form-control" formControlName="name" />
                    </div>

                    <div class="form-group">
                        <label>Description:</label>
                        <textarea id="appDescription" class="form-control"
                                  formControlName="description"></textarea>
                    </div>

                    <div class="form-group">
                        <label>Is visible?</label>
                        <div>
                            <p-inputSwitch name="isVisible" formControlName="appIsVisible"></p-inputSwitch>
                                                

                        </div>

                    </div>

                    <div class="form-group">
                        <label>Is offered?</label>
                        <div>
                            <p-inputSwitch name="isVisible" formControlName="appIsOffered"></p-inputSwitch>

                        </div>

                    </div>

                    <div class="form-group">
                        <label>White roles:</label>
                        <kendo-multiselect
                        class="form-control"
                        [data]="roles?.roles"
                        formControlName="appIsWhiteRoles"></kendo-multiselect>                    
                    </div>

                    <div class="form-group">
                        <label>Grey roles:</label>
                        <kendo-multiselect
                        class="form-control"
                        [data]="roles?.roles"
                        formControlName="appIsGreyRoles"></kendo-multiselect>                    
                    </div>

                    <div *ngIf="appForm.controls.appType && appForm.controls.appType?.value != archiveAppId" class="form-group">
                        <label>Url:</label>
                        <input class="form-control" formControlName="url" />
                    </div>

                    <div *ngIf="appForm.controls.appType && appForm.controls.appType?.value != archiveAppId" class="form-group">
                        <label>Alternate url:</label>
                        <input class="form-control" formControlName="alternateUrl" />
                    </div>

                    <div class="form-group ">
                        <label>Thumbnail image:</label>

                        <file-upload [uploadSaveUrl]="uploadSaveUrl" [alowedTypes]="alowedTypes" (fileUploaded)="isUploaded($event)"></file-upload>

                    </div>

                    <div class="form-group">
                        <button type="submit" style="float:right;" class="btn-deloitte btn" [disabled]="!appForm.valid"
                                (click)="createApp()">
                            Save
                        </button>
                    </div>
                </form>


            </div>
        </div>
    </div>
</itp-panel>