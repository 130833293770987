<div *ngIf="data" style="margin-left: 4rem; margin-right: 4em;">
  <h4 style="font-size: 1.5em; margin-bottom: 1em;">Archive App Documents</h4>
<kendo-grid [data]="gridView" [pageSize]="pageSize" [skip]="skip"
[sort]="sort" [sortable]="true" (sortChange)="sortChange($event)" [pageable]="true" (pageChange)="pageChange($event)" [groupable]="false"
[scrollable]="'scrollable'" [filter]="filter" (filterChange)="filterChange($event)" filterable="menu" [columnMenu]="true"
[resizable]="true" [reorderable]="true" [loading]="false">

  <kendo-grid-column field="period" title="Period" width="100px" format="{0:d}">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.period | date: 'yyyy/MM/dd'}}
  </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="name" title="Name"></kendo-grid-column>
  <kendo-grid-column field="tags" title="Tags"></kendo-grid-column>   
  <kendo-grid-command-column title=""  width="150px">
      <ng-template kendoGridCellTemplate let-dataItem>
          <a class="btn btn-deloitte" (click)="editArchiveAppDocumentLink(dataItem)"> Edit</a>
         <a class="btn btn-deloitte" style="background-color: #97999B; color: white; width: 60px;" (click)="remove(dataItem)">Delete</a> 
      </ng-template> 
  </kendo-grid-command-column>
  <kendo-grid-column field="blobId" title="File" width="80px">        
    <ng-template kendoGridCellTemplate let-dataItem>          
        <file-download [fileType]="dataItem.type" [file]="dataItem.blobId" (onFileDownload)="downloadFiles($event)"></file-download>
    </ng-template> 
</kendo-grid-column> 
</kendo-grid>
</div>