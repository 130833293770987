﻿import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';


@Component({
    selector: 'vat-countries-overview',
    templateUrl: './overview.component.html'
})
export class VatCountriesOverviewComponent implements OnInit {
    legalEntityId: string;
    constructor(private readonly route: ActivatedRoute) { }

    ngOnInit() {
        this.legalEntityId = this.route.snapshot.paramMap.get('legalEntityId');
    }
}