﻿<div>
    <h1>Unauthorized access attempt</h1>
    <div *ngIf="userInfo?.name">
        <div *ngIf="userInfo?.Roles && userInfo.Roles.length > 0; else other_content">
            <p>Hey {{userInfo.name}}, we successfully authenticated you but it seems you do not have any ITP user roles assigned is this possible?</p>
            <p>Please contact your local administrator to request access.</p>
        </div>


        <ng-template> #other_content>
            <div *ngIf="!userInfo?.Roles || userInfo?.Roles?.length > 0">
                <p>You were sucessfully authenticated, {{userInfo.name}}, but do not seem to have permissions to access (this segment of) ITP.</p>
                <p>Please contact your local administrator to request access.</p>
            </div>
        </ng-template>
    </div>
    <div *ngIf="!userInfo?.name">
        <p>We were unable to authenticate you, did you get redirected to DIAS? If not, please let our technical team know your browser and version!</p>
        <p>Please contact your local administrator to request access.</p>
    </div>

</div>