<span>
    <span (click)="onClick()">
        <span [ngSwitch]="true" style="cursor: pointer;">
            <i *ngSwitchCase="fileType==='xlsx'||fileType==='xls'" class="far fa-file-excel" style="font-size: 1.8em;"></i>
            <i *ngSwitchCase="fileType==='jpeg'||fileType==='jpg'" class="far fa-file-image" style="font-size: 1.8em;"></i>
            <i *ngSwitchCase="fileType==='pdf'" class="fas fa-file-pdf" style="font-size: 1.8em;color:red"></i>
            <i *ngSwitchDefault class="fa fa-download text-primary" style="font-size: 1.8em;"></i>
        </span>
        <span style="font-size: 1.3em; margin-left:0.5em" *ngIf="fileName" [innerHtml]="fileName"></span>
        <p *ngIf="!file" class="help-block">No documents available yet</p>
    </span>
</span>