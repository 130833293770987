﻿import { Component, Input, OnInit } from "@angular/core";

import { settings } from "../../../environments/environment";
import { MenuItemModel } from "../../shared/models/menu-item";
import { MenuItem } from "./layout-menu.models";
import { DataService } from '../../shared/services/data.service';
import { UrlPath } from '../../shared/common/url-path';
import { CurrentUserService } from "../../shared/services/current-user-service";
import { CurrentUserModel } from "../../shared/models/current-user";
import { MenuSharedService } from "../../shared/services/menu.shared.service";

@Component({
    selector: 'layout-menu',
    templateUrl: './layout-menu.component.html',
})
export class LayoutMenuComponent implements OnInit {
    @Input() appMenuItems: MenuItemModel[];
    menuItems: MenuItem[];
    constructor(public readonly dataService: DataService,readonly menuService: MenuSharedService, private userInfoService: CurrentUserService) {
    }

    ngOnInit() {
        this.menuService.getMenus().subscribe((data: any) => {            
            this.appMenuItems = data;
            this.sortMenus();
        });
    }

    private sortMenus() {
        this.appMenuItems.sort(this.sortByViewOrder);
        this.menuItems = [];
        for (var i = 0; i < this.appMenuItems.length; i++) {
            let dto = this.appMenuItems[i];
            if (!dto.parentMenuItemId || dto.parentMenuItemId == "") {

                let menuItem = this.createMenuItem(dto);
                this.menuItems.push(menuItem);
                for (let j = 0; j < dto.children.length; j++) {
                    let child = dto.children[j];
                    menuItem.children.push(this.createMenuItem(child));
                }
            }
        }        
    }

    sortByViewOrder(a: MenuItemModel, b: MenuItemModel): number {
        return a.viewOrder - b.viewOrder;
    }

    createMenuItem(dto: MenuItemModel): MenuItem {
        var menuItem = <MenuItem>{
            item: dto,
            imageUrl: dto.image ? `data:image/png;base64,${dto.image.toString()}` : null,
            hoverImageUrl: dto.hoverImage ? `data:image/png;base64,${dto.hoverImage.toString()}` : null,
            children: []
        };
        if (dto.url) {
            menuItem.url = (settings.baseUrl + dto.url).split('//').join('/');
        }
        menuItem.style = {
            'background': menuItem.imageUrl ? "url('" + menuItem.imageUrl + "') 10px 15px / 30px 30px no-repeat" : ''
        };
        return menuItem;
    }
}