import { BrowserModule  } from '@angular/platform-browser';
import { NgModule, ErrorHandler, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';

import { AppComponent } from './app.component';
import { LayoutFooterComponent } from "./layout/layout-footer/layout-footer.component";
import { LayoutHeaderComponent } from "./layout/layout-header/layout-header.component";
import { LayoutSpinnerComponent } from "./layout/layout-spinner/layout-spinner.component";
import { LayoutMenuComponent } from "./layout/layout-menu/layout-menu.component";
import { MenuComponent } from "./layout/layout-menu/components/menu/menu.component";
import { CommonModule } from '@angular/common';
import { NotAuthorizedComponent } from './errors/not-authorized.component';
import { IncorrectOrganizationComponent } from './errors/incorrect-organization.component';
import { AdminModule } from "./admin/admin.module";
import { NewsComponent } from './news/news.component';
import { LayoutLeftNavComponent } from './layout/layout-left-nav/layout-left-nav.component';
import { AuthenticatedRouteActivator } from './shared/guards/authenticated-activator.guard';
import { NoRightClickDirective } from './admin/directives/no-right-click.directive';
import { AdminActivatorGuard } from './shared/guards/admin-activator.guard';
import { LegalEntityModule } from './legal-entity/legal-entity.module';
import { SurveyActivatorGuard } from './shared/guards/survey-activator.guard';
import { InternalLoginComponent } from './internal-login/internal-login.component';
import { LoadingInterceptor } from './shared/interceptor/loading.interceptor';



@NgModule({
    declarations: [ 
        AppComponent,
        LayoutFooterComponent,
        LayoutHeaderComponent,
        LayoutMenuComponent,
        LayoutLeftNavComponent,
        MenuComponent,
        LayoutSpinnerComponent,
        NewsComponent,
        NotAuthorizedComponent,
        IncorrectOrganizationComponent,
        InternalLoginComponent,
        NoRightClickDirective
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        SharedModule,
        AdminModule,
        LegalEntityModule,
        HttpClientModule,
        CommonModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot(),
    ],
    providers: [
        AuthenticatedRouteActivator,
        AdminActivatorGuard,
        SurveyActivatorGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoadingInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
