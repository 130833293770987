import { Component, OnInit, Testability } from "@angular/core";
import { DataService } from "../../../../../shared/services/data.service";
import { UrlPath } from "../../../../../shared/common/url-path";
import { ActivatedRoute } from "@angular/router";
import { SurveyResultModel } from "../../../../../shared/models/knowledgehub/surveyresult.model";
import { CurrentUserService } from "../../../../../shared/services/current-user-service";
import { CurrentUserModel } from "../../../../../shared/models/current-user";
import { DataGridService } from "../../../../../shared/services/data-grid.service";
import { StructureModel } from "../../../models/structure.model";
import { QuestionModel } from "../../../models/question.model";
import { TitleModel } from "../../../models/title.model";

@Component({
    selector: 'add-titles-structure',
    templateUrl: './add-titles-structure.component.html',
    styleUrls: ['./add-titles-structure.component.scss']
})
export class AddTitlesStructure implements OnInit {
    constructor(readonly dataService: DataService, readonly route: ActivatedRoute,
        readonly currentUserService: CurrentUserService, readonly dataGridService: DataGridService
    ) { }
    emptyTitle: TitleModel = {
        id: null,
        name: "Select a title...",
        parentTitleId: null,
        parentTitle: null,
        topicId: null,
        order: null,
    };

    data: any;
    topics: any = [];
    topicsTemp: any = [];
    isReady = false;
    topicKey: any;
    questions: any[];
    topic: any;
    titleQuestionsToSave: any[] = [];
    titles: TitleModel[];
    topicId: string;
    currentUser: CurrentUserModel;
    isNew = false;
    source: any;
    structures: StructureModel[];
    titleQuestions: any[];
    filter: string;
    userRoles: any[] = [];

    ngOnInit() {
        this.currentUserService.getUserInfo().subscribe(data => {
            this.currentUser = data;
            this.userRoles = this.currentUser.roles;
            this.topicKey = this.route.snapshot.paramMap.get('topicKey');
            if (this.topicKey === "new" || this.topicKey === "" || this.topicKey == null || this.topicKey === " ")
                this.isNew = true;
            this.dataService.get<any>(UrlPath.getPurchasedTopics()).subscribe(data => {
                if (this.isNew === false) {
                    this.topicsTemp = data;
                    this.topicId = this.topicsTemp.find((x: any) => x.topicKey === this.topicKey).topicId;
                } else {
                    this.topics = data;
                }
            });
            if (this.isNew === false) {
                this.dataService.put<any>(UrlPath.titlesApi_getQuestionKeys(this.topicKey), { topicKey: this.topicKey })
                    .subscribe(data => {
                        this.questions = data;
                        this.dataService.get<any>(UrlPath.titlesApi_getAll(this.topicKey))
                            .subscribe(data => {
                                this.titles = data;
                                console.log(this.titles, "Titles");
                                this.source = this.titles;
                                this.dataService.get<any>(UrlPath.titleQuestionsApi_getAll(this.topicKey))
                                    .subscribe(
                                        (data) => {
                                            this.titleQuestions = data;
                                            console.log(this.titleQuestions, "TitleQuestions");
                                            this.createStructure();
                                        });
                            }
                            );
                    });
            }

        });



    }

    public getQuestions(): void {
        this.topicKey = this.topic.topicKey;
        console.log(this.topicKey);
        this.topicId = this.topics.find((x: any) => x.topicKey === this.topicKey).topicId;
        this.dataService.put<any>(UrlPath.titlesApi_getQuestionKeys(this.topicKey), { topicKey: this.topicKey })
                    .subscribe(data => {
                        this.questions = data;
                        this.dataService.get<any>(UrlPath.titlesApi_getAll(this.topicKey))
                            .subscribe(data => {
                                this.titles = data;
                                this.source = this.titles;
                                this.dataService.get<any>(UrlPath.titleQuestionsApi_getAll(this.topicKey))
                                    .subscribe(
                                        (data) => {
                                            this.titleQuestions = data;
                                            this.createStructure();
                                        });
                            }
                            );
                    });
        this.isReady = true;
    }

    public addNew(): void {
        let model: any = {
            isVisible: true,
            name: this.filter,
            order: 1,
            topicKey: this.topicKey
        }

        this.dataService.post(UrlPath.titlesApi_save(), model).subscribe(() => {
            console.log(model);
            this.dataService.get<any>(UrlPath.titlesApi_getAll(this.topicKey))
                .subscribe(data => {
                    this.titles = data;
                    console.log("titles", this.titles);
                    let lastOccurence = data.find((x: any) => x.name == this.filter);
                    this.source.push({
                        isVisible: true,
                        name: this.filter,
                        order: 1,
                        topicKey: this.topicKey,
                        id: lastOccurence.id
                    });
                    this.handleFilter(this.filter);
                });
        });


    }

    public createStructure(): void {
        this.structures = [];
        this.questions.forEach(question => {
            let questionToPush: QuestionModel = { questionKey: question, order: 0}
            const titleQuestionRelated: any = this.titleQuestions.find(x => x.questionKey === question);
            let structure: StructureModel = { question: questionToPush, title: new TitleModel }
            if (titleQuestionRelated != null) {
                questionToPush.order = titleQuestionRelated.order;
                let titleToPush: TitleModel = this.titles.find(x => x.id === titleQuestionRelated.titleId);
                titleToPush.parentTitle = new TitleModel;
                if (titleToPush.parentTitleId != null) {
                    titleToPush.parentTitle = this.titles.find(x => x.id === titleToPush.parentTitleId)
                }
                structure.title = titleToPush;
            } else {
                structure.title = this.emptyTitle;
            }
            this.structures.push(structure);
        });
        console.log(this.structures);
        this.isReady = true;
    }

    public saveStructure() {
        let titleQuestionToAdd: any;

        this.structures.forEach(structure => {
            if (structure.title != null && structure.title.id != "00000000-0000-0000-0000-000000000000" && structure.title.id != null) {
                titleQuestionToAdd = {
                    questionKey: structure.question.questionKey,
                    titleId: structure.title.id,
                    order: structure.question.order
                }
                console.log("titleQuestionToAdd", titleQuestionToAdd);
                let title = {
                    id: structure.title.id,
                    isVisible: true,
                    name: structure.title.name,
                    order: structure.title.order,
                    topicKey: this.topicKey,
                    parentTitleId: ''
                }
                console.log("title", title);
                if (structure.title.parentTitle != null) {
                    title.parentTitleId = structure.title.parentTitle.id;
                }
                this.titleQuestionsToSave.push(titleQuestionToAdd);
                this.dataService.post(UrlPath.titlesApi_save(), title).subscribe(() => {

                });
            } else {
                this.structures = this.structures.filter(obj => obj !== structure);
            }

        })
        let dataToAdd = {
            TopicKey: this.topicKey,
            titleQuestions: this.titleQuestionsToSave
        }
        console.log(dataToAdd);
        this.dataService.post(UrlPath.titleQuestionsApi_saveAll(), dataToAdd).subscribe((data) => {
            window.location.href = UrlPath.titles();
        })

    }

    public getTitleQuestions(): void {
        this.dataService.get<any>(UrlPath.titleQuestionsApi_getAll(this.topicKey)).subscribe((data) => {
            this.titleQuestions = data;
        })
    }

    public handleFilter(value: any) {
        this.filter = value;
        this.titles = this.source.filter((s: any) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    }

    public valueChange(value: any) {
    }
}

