﻿import { Component, OnInit, Input } from '@angular/core';

import { GridComponent } from "../../../../shared/components/grid/grid.component";
import { DataGridService } from "../../../../shared/services/data-grid.service";
import { AlertService } from "../../../../shared/services/alert.service";

import { UrlPath } from "../../../../shared/common/url-path";
import { CountryOfVATEstablishmentOverviewModel } from "../../../../shared/models/countries-of-vat-establishments/country-of-vat-establishment-overview.model";

@Component({
    selector: 'list-vat-countries',
    templateUrl: './list-vat-countries.component.html',
    providers: [DataGridService]
})
export class ListVatCountriesComponent extends GridComponent<CountryOfVATEstablishmentOverviewModel> implements OnInit {
    @Input() legalEntityId: string;

    constructor(readonly dataGridService: DataGridService,
        readonly alertService: AlertService) {
        super(dataGridService, alertService);
    }

    ngOnInit() {
        this.readUrl = UrlPath.countriesOfVATEstablishmentApi_getByLegalEntity(this.legalEntityId);
        this.putUrl = UrlPath.countriesOfVATEstablishmentApi;
        this.read();
    }

    protected New(): CountryOfVATEstablishmentOverviewModel {
        var model = new CountryOfVATEstablishmentOverviewModel();
        return model;
    }

    appLink(model: CountryOfVATEstablishmentOverviewModel): string {
        return UrlPath.reportingCountries(model.Id);
    }

    addNewAppLink(): string {
        return UrlPath.countriesOfVATEstablishment_create(this.legalEntityId);
    }

    editAppLink(model: CountryOfVATEstablishmentOverviewModel): string {
        return UrlPath.countriesOfVATEstablishment_edit(this.legalEntityId, model.Id);
    }

    remove(model: CountryOfVATEstablishmentOverviewModel) {
        this.removeUrl = UrlPath.countriesOfVATEstablishmentApi_delete(model.Id);
        this.dataGridService.loading = true;
        this.dataGridService.deleteUrlPath = this.removeUrl;
        this.dataGridService.removeById();
    }
}