﻿import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from "@angular/common/http";

import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

import { LoadingService } from "../services/loading.service";

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
    counter = 0;
   
    constructor(private readonly loadingService: LoadingService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.checkRequest(req);
        return next.handle(req)
            .pipe(tap((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    this.checkResponse(event);
                }
            }, (error: any) => {
                if (error instanceof HttpErrorResponse) {
                    this.checkError();
                }
            }));
    }

    private checkRequest(httpRequest: HttpRequest<any>): void {
        if (this.hideLoading(httpRequest.url)) {
            return;
        }
        this.counter++;
        this.setLoading();
    }

    private checkResponse(httpResponse: HttpResponse<any>): void {
        if (this.hideLoading(httpResponse.url)) {
            return;
        }
        this.counter--;
        this.setLoading();
    }

    private checkError(): void {
        this.counter = 0;
        this.setLoading();
    }

    private hideLoading(url: string): boolean {
        return this.loadingService.hidingUrls.some(x => url.includes(x));
    }

    private setLoading(): void {
        this.loadingService.loading = this.counter > 0;
    }
}