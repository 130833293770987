﻿<div style="padding-right: 3em; padding-left: 2.2em;">
    <h4 style="font-size: 1.5em; margin-bottom: 1em;">Legal Entities</h4>
    <div>
        <kendo-grid [data]="data" [scrollable]="'scrollable'" [resizable]="true" [loading]="false"
                    (dataStateChange)="dataGridService.dataStateChange($event)"
                    (edit)="editHandler($event)" (add)="addHandler()" (remove)="removeHandler($event.dataItem)">

            <ng-template kendoGridToolbarTemplate>
                <a class="btn btn-deloitte" [attr.href]="addLegalEntity()">Add new</a>
            </ng-template>
            <kendo-grid-column field="Name" title="Name" width="150">
                <ng-template kendoGridCellTemplate let-dataItem>
                    {{dataItem.legalEntityName}}
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="Code" title="Code" width="150">
                <ng-template kendoGridCellTemplate let-dataItem>
                    {{dataItem.legalEntityCode}}
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="Jurisdiction" title="Jurisdiction" width="100">
                <ng-template kendoGridCellTemplate let-dataItem>
                    {{dataItem.jurisdiction}}
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="Form" title="Form" width="150">
                <ng-template kendoGridCellTemplate let-dataItem>
                    {{dataItem.legalEntityForm}}
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="SalesType" title="Sales type" width="150">
                <ng-template kendoGridCellTemplate let-dataItem>
                    {{dataItem.salesType}}
                </ng-template>
            </kendo-grid-column>
            <!--<kendo-grid-column field="Visible" title="Visible" width="85">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <span *ngIf="dataItem.isVisible">Yes</span>
                    <span *ngIf="!dataItem.isVisible">No</span>
                </ng-template>
            </kendo-grid-column>-->
            <kendo-grid-command-column title="" width="250">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <a class="btn btn-deloitte" style="width: 60px;" [attr.href]="editLegalEntity(dataItem.legalEntityId)">Edit</a>
                    <a class="btn btn-deloitte" style="background-color: #97999B; color: white; width: 60px;"
                       (click)="remove(dataItem.legalEntityId)">Delete</a>
                       <a class="btn btn-deloitte" style="color: white; width: 120px;"
                       [attr.href]="getEstablishments(dataItem.legalEntityId)">Establishments</a>
                </ng-template>
            </kendo-grid-command-column>
        </kendo-grid>
    </div>
</div>