﻿import { Component, Input, OnInit, ElementRef } from "@angular/core";
import { MenuItemModel } from "../../../../shared/models/menu-item";
import { MenuItem } from "../../layout-menu.models";
import { ImagePath } from "../../../../shared/common/image-path";

@Component({
    selector: 'itp-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
    togglerImage = ImagePath.togglerImage;
    isMenuToggled: boolean;
    isToggled = false;
    @Input() items: MenuItem[];
    

    constructor(){}

    ngOnInit(): void {
        
    }

    toggleMenu(){
        if(!this.isToggled){
            document.getElementById("left-nav").style.width = '250px';
            document.getElementById("page-content").style.left = '200px';
            document.getElementById("footer").style.left = '-200px'; 

        }
        this.isToggled = !this.isToggled;
        // if(this.isToggled == false){
        //     
        // }
    }

    open(){
        document.getElementById("left-nav").style.width = '250px';
        document.getElementById("page-content").style.left = '200px';
        document.getElementById("footer").style.left = '-200px';  
    }

    close(){
        if(!this.isToggled){
            document.getElementById("left-nav").style.width = null;
            document.getElementById("page-content").style.left = '60px';
            document.getElementById("footer").style.left = '-60px';

            this.items.forEach((item) =>{
            item.isCollapsed = false;});
        
    }
}
}