﻿import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GridModule } from '@progress/kendo-angular-grid';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { IntlModule } from '@progress/kendo-angular-intl';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { SharedModule } from '../../shared/shared.module';
import { routing } from "./apps.routing";
import { UploadModule } from '@progress/kendo-angular-upload';
import { AppsOverviewComponent } from "./components/overview/overview.component";
import { ListAppsComponent } from "./components/list-apps/list-apps.component";
import { AppNewAppComponent } from "./components/add-new-app/add-new-app.component";
import { EditAppComponent } from "./components/edit-app/edit-app.component";
import { AppSurveyPermissionComponent } from './components/app-survey-permission/app-survey-permission.component'
;
import { ListSurveyPermissionComponent } from './components/list-survey-permission/list-survey-permission.component'
;
import { ArchiveAppDocumentComponent } from './components/archive-app/archive-app.component'
;
import { ListArchiveAppDocumentsComponent } from './components/list-archive-apps/list-archive-apps.component'

import { AppPermissionOverviewComponent } from './components/app-permission/app-permission-overview/app-permission-overview.component';
import { AppPermissionDetailsComponent } from './components/app-permission/app-permission-details/app-permission-details.component';

;
import {InputSwitchModule} from 'primeng/inputswitch';
;
@NgModule({
    declarations: [
        AppsOverviewComponent,
        ListAppsComponent,
        AppNewAppComponent,
        EditAppComponent,
        AppSurveyPermissionComponent,
        ListSurveyPermissionComponent ,
        ArchiveAppDocumentComponent ,
        ListArchiveAppDocumentsComponent,
        AppPermissionOverviewComponent,
        AppPermissionDetailsComponent],
    imports: [
        routing,
        FormsModule,
        SharedModule,
        GridModule,
        DialogModule,
        DropDownsModule,   
        IntlModule,
        DateInputsModule,
        CommonModule,
        ReactiveFormsModule,
        UploadModule,
        InputSwitchModule
    ],

})
export class AppsModule { }