<itp-panel>

    <div style="margin-left: 2.2rem;" *ngIf="permissionModel">
        <h4 style="font-size: 1.5em; margin-bottom: 1em;" *ngIf="topicKey==''"> Survey - Add permission</h4>
        <h4 style="font-size: 1.5em; margin-bottom: 1em;" *ngIf="topicKey!=''">Survey - Edit permission</h4>

        <div class="card col-7">
            <div class="card-body p-4">
                <div class="form-group">
                    <h3 class="card-title mb-4" style="font-size: 1.2rem;">Add Permission</h3>
                </div>

                <form [formGroup]="surveyPermissionForm">


                    <div class="form-group">
                        <label>Topics:</label>
                        <div *ngIf="topicKey==''">
                            <kendo-multiselect style="height: auto;" [data]="permissionModel.topics" 
                            [(ngModel)]="SelectedTopics" class="form-control"
                                [textField]="'topicName'" [autoClose]="false"  [valueField]="'topicId'" id="topicId"
                                formControlName="surveyTopics"></kendo-multiselect>
                        </div>
                        <div *ngIf="topicKey!='' && SelectedTopics && SelectedTopics[0]">
                            {{SelectedTopics[0].topicName}}
                        </div>


                    </div>

                    <div class="form-group">
                        <label>Countries:</label>
                        <div>
                            <kendo-multiselect [data]="permissionModel.jurisdictions"
                                [(ngModel)]="SelectedJurisdictions" class="form-control" [textField]="'name'"
                                [valueField]="'jurisdictionId'" id="jurisdictionId" [autoClose]="false"
                                formControlName="surveyCountries"></kendo-multiselect>
                        </div>


                    </div>



                    <div class="form-group">
                        <button type="submit" style=" float:right;" class="btn-deloitte btn" [disabled]="!surveyPermissionForm.valid"
                            (click)="save()">Save</button>
                    </div>
                </form>
            </div>
        </div>

    </div>


</itp-panel>