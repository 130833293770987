import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../../../shared/services/data.service';
import { MenuItemModel } from '../../../shared/models/menu-item';
import { UrlPath } from '../../../shared/common/url-path';
import { MenuFormComponent } from './menu-form.component';
import { MenuService } from '../services/menu-service.service';
import { MenuTreeComponent } from '../components/menu-tree.component';

@Component({
  selector: 'itp-menu-management',
  templateUrl: './menu-management.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuManagementComponent implements OnInit {
  parentMenuItemId: any;

  constructor(private dataService: DataService, private menuService: MenuService) { }
  menuItem: MenuItemModel;
  @ViewChild(MenuFormComponent) menuFormComponent: MenuFormComponent;
  @ViewChild(MenuTreeComponent) menuTreeComponent: MenuTreeComponent;
  ngOnInit() {
  }
  onSave(menuItem: any) {
    this.dataService.post<MenuItemModel>(UrlPath.saveMenu, {Id:menuItem.menuItemId, Name: menuItem.name, Url: menuItem.url, ViewOrder: menuItem.ViewOrder, ParentMenuItemId: this.menuService.parentMenuItemId })
    .subscribe(
      data => this.menuTreeComponent.loadMenus()
    )
  }

  onMenuSelected(menuItem: MenuItemModel) {
    this.menuItem = menuItem;
    this.menuFormComponent.menuItem = menuItem;
    this.menuFormComponent.loadMenuForm();
  }

}
