import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { CurrentUserService } from '../services/current-user-service';
import { CurrentUserModel } from '../models/current-user';
import { MenuSharedService } from '../services/menu.shared.service';
import { MenuItemModel } from '../models/menu-item';
import { UrlPath } from '../common/url-path';
import { publishReplay, refCount } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SurveyActivatorGuard implements CanActivate {
  surveyRoles: Observable<string[]>;
  constructor(private userInfoService: CurrentUserService, private router: Router) { }
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    let user = await this.userInfoService.getUserInfo().toPromise() as CurrentUserModel;
     let surveyRoles = ['ITPFreeContentManager', 'ITPUser', 'VATCrossCountryInfoPagesDeloitteContentUser', 'VATCrossCountryInfoPagesDeloitteContentProvider', 'VATCrossCountryInfoPagesAdmin',
       'VATCrossCountryInfoPagesClient', 'ITPMasterdataEditor', 'ITPAdmin', 'VATCrossCountryInfoPagesDeloitteBelgiumUser']

    if (user === null || user === undefined || user.roles === undefined || user.roles.length === 0) {
      this.router.navigate(['Error/Unauthorized']);
      return false;
    }
    if (!this.findCommonElements(surveyRoles, user.roles)) {
      this.router.navigate(['Error/Unauthorized']);
      return false;
    }
    return true;
  }

  // Iterate through each element in the 
  // first array and if some of them  
  // include the elements in the second 
  // array then return true. 
  findCommonElements(arr1: any, arr2: any) {
    return arr1.some((item: any) => arr2.includes(item))
  }
}
