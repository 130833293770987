﻿import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { DataService } from "../../../../shared/services/data.service";
import { UrlPath } from "../../../../shared/common/url-path";
import { EditCountryOfVATEstablishmentModel } from "../../../../shared/models/countries-of-vat-establishments/edit-country-of-vat-establishment.model";

@Component({
    selector: 'edit-vat-country',
    templateUrl: './edit-vat-country.component.html',
    providers: [DataService]
})
export class EditVatCountryComponent implements OnInit {
    entity: EditCountryOfVATEstablishmentModel;
    id: string;
    loading: boolean;
    constructor(private readonly dataService: DataService,
        private readonly route: ActivatedRoute) { }

    ngOnInit() {
        this.id = this.route.snapshot.paramMap.get('id');
        this.get();
    }

    get() {
        this.loading = true;
        this.dataService.get<EditCountryOfVATEstablishmentModel>(UrlPath.countriesOfVATEstablishmentApi_getEditModel(this.id)).subscribe(model => {
            this.entity = model;
            this.loading = false;
        });
    }

    save() {
        this.dataService.put<boolean>(UrlPath.countriesOfVATEstablishmentApi_edit, this.entity).subscribe((success: boolean) => {
            if (success) {
                window.location.href = UrlPath.legalEntities(this.entity.ClientId);
            }
        });
    }
}