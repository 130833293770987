﻿/// <reference path="components/panel/panel.component.ts" />
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ItpPanel } from './components/panel/panel.component'
import { DataService } from './services/data.service';;
import { FileUploadComponent } from './components/file-upload/file-upload.component'
import { UploadModule } from '@progress/kendo-angular-upload';
import { DataGridService } from './services/data-grid.service';;
import { LoadingService } from "./services/loading.service";
import { FileDownloadComponent } from './components/file-download/file-download.component'
import { FileService } from './services/file.service';
import { MenuSharedService } from './services/menu.shared.service';;
import { RemoveItemComponent } from './components/remove-item/remove-item.component'
import { WindowModule, DialogModule } from '@progress/kendo-angular-dialog';
import { SafePipe } from './pipes/safe.pipe';
import { FileImportExportComponent } from './components/file-import-export/file-import-export.component';
@NgModule({
    declarations: [ItpPanel,
         FileUploadComponent,
         FileDownloadComponent,
         RemoveItemComponent,
         SafePipe,
         
         FileImportExportComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        UploadModule,
        DialogModule,
        WindowModule
    ],
    exports: [
        ItpPanel,
        FileUploadComponent,
        FileDownloadComponent,
        RemoveItemComponent,
        FileImportExportComponent
    ],
    providers: [DataService, DataGridService, FileService, MenuSharedService, SafePipe, LoadingService]
})
export class SharedModule { }