﻿import { Component, OnInit } from '@angular/core';

import { GridComponent } from "../../../../../shared/components/grid/grid.component";
import { DataGridService } from "../../../../../shared/services/data-grid.service";
import { AlertService } from "../../../../../shared/services/alert.service";

import { UrlPath } from "../../../../../shared/common/url-path";
import { AppPermissionOverviewModel } from "../models/app-permission-overview.model";
import { DataService } from '../../../../../shared/services/data.service';

@Component({
    selector: 'app-permission-overview',
    templateUrl: './app-permission-overview.component.html',
    providers: [DataGridService]
})
export class AppPermissionOverviewComponent extends GridComponent<AppPermissionOverviewModel> implements OnInit {
    constructor(readonly dataGridService: DataGridService,
        readonly alertService: AlertService, readonly dataService: DataService
    ) {
        super(dataGridService, alertService);
    }

    data: any;
    loading: boolean;
    ngOnInit() {
        this.loadAppPermissions();
    }

    private loadAppPermissions() {
        this.loading = true;
        this.dataService.get(UrlPath.appPermissionApi_getAll()).subscribe(data => {
            this.data = data;
            this.loading = false;
        });
    }

    protected New(): AppPermissionOverviewModel {
        var model = new AppPermissionOverviewModel();
        return model;
    }

    addAppPermission(): string {
        return UrlPath.appPermission_details('new');
    }

    editAppPermission(appFunctionalityId: string): string {
        return UrlPath.appPermission_details(appFunctionalityId);
    }

   

    remove(appFunctionalityId: string) {
        this.removeUrl = UrlPath.appPermissionApi_deleteAppPermission(appFunctionalityId);
        this.loading = true;
        this.dataService.delete(UrlPath.appPermissionApi_deleteAppPermission(appFunctionalityId)).subscribe(t => {
            this.loadAppPermissions();
        });
    }

   
    }
