import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridModule } from '@progress/kendo-angular-grid';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { SharedModule } from '../shared/shared.module';
import { ContextMenuModule } from '@progress/kendo-angular-menu';
import { ReactiveFormsModule } from '@angular/forms';

import { MenuManagementComponent } from './menu-management/components/menu-management.component';
import { MenuTreeComponent } from './menu-management/components/menu-tree.component';
import { MenuFormComponent } from './menu-management/components/menu-form.component';
import { AppsModule } from "./apps/apps.module";
import { IndustriesModule } from "./industries/industries.module";
import { ClientsModule } from "./clients/clients.module";
import { LegalEntitiesModule } from "./legal-entities/legal-entities.module";
import { VATCountriesModule } from "./vat-countries/vat-countries.module";
import { MenuService } from './menu-management/services/menu-service.service';
import { routing } from "./admin.routing";
import { SurveysModule } from './survey/surveys.module';

@NgModule({
    declarations: [
        MenuManagementComponent,
        MenuTreeComponent,
        MenuFormComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        SurveysModule,
        ContextMenuModule,
        TreeViewModule,
        GridModule,
        ReactiveFormsModule,
        AppsModule,
        IndustriesModule,
        ClientsModule,
        LegalEntitiesModule,
        VATCountriesModule,
        routing
    ],
    providers:[MenuService]
})
export class AdminModule { }
