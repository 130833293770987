﻿import { settings } from '../../../environments/environment';

export class ImagePath {
    private static imagesPath = `${settings.baseUrl}Content/images`;
    static deloitteLogo = `${ImagePath.imagesPath}/logo.png`;
    static logoImage = `${ImagePath.imagesPath}/logo_1.svg`;
    static titleImage = `${ImagePath.imagesPath}/title-image.png`;
    static togglerImage = `${ImagePath.imagesPath}/toggler-image.png`;
    static headerImage = `${ImagePath.imagesPath}/newBackgroundImage.jpg`;
    static surveyImage = `${ImagePath.imagesPath}/default_picture_info_pages.png`;
    static questionMark = `${ImagePath.imagesPath}/question-circle-svg.png`;
    static questionChanged = `${ImagePath.imagesPath}/sync-alt-svg.svg`;
    static surveyList1 = `${ImagePath.imagesPath}/survey-list-1.jpg`;
    static surveyList2 = `${ImagePath.imagesPath}/survey-list-2.jpg`;
    static surveyList3 = `${ImagePath.imagesPath}/survey-list-3.jpg`;


}