import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MenuItemModel } from '../../../shared/models/menu-item';
import { FormControl, FormGroup } from '@angular/forms';
import { Validators } from '@angular/forms';
import { MenuService } from '../services/menu-service.service';
@Component({
  selector: 'menu-form',
  templateUrl: './menu-form.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuFormComponent implements OnInit {

  constructor(readonly menuService: MenuService) { }

  ngOnInit() {
    if (this.menuItem) {
      this.loadMenuForm();
    }
  }
  loadMenuForm() {
    this.menuForm.patchValue({
      menuItemId:this.menuItem.menuItemId,
      name: this.menuItem.name,
      url: this.menuItem.url,
      ViewOrder: this.menuItem.viewOrder
    });
  }

  @Input() menuItem: MenuItemModel;
  @Input() formText: string;
  @Output() save = new EventEmitter<any>();



  menuForm = new FormGroup({
    menuItemId: new FormControl(),
    name: new FormControl('', Validators.required),
    url: new FormControl('', Validators.required),
    ViewOrder: new FormControl('', Validators.required),
    image: new FormControl('')
  });

  onSubmit() {
    this.save.emit(this.menuForm.value);
  }
}
