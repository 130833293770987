<div style="margin-left: 4rem; margin-right: 4em;">
    <h4 style="margin-bottom: 1em;  font-size: 1.5em;">
        <ng-container *ngIf="!isNew">{{topicKey}}</ng-container>     
        <kendo-dropdownlist  (valueChange)="getQuestions($event)" *ngIf="isNew == true" [textField]="'topicName'" 
        [data]="topics" [(ngModel)]="topic" [valueField]="'topicKey'" [style.width]="'30%'"></kendo-dropdownlist>
        - Titles structure</h4>

    <kendo-grid *ngIf="isReady" [data]="structures" [groupable]="false" [scrollable]="'scrollable'" [resizable]="true"
       [loading]="false"
        (dataStateChange)="dataGridService.dataStateChange($event)">
        <ng-template kendoGridToolbarTemplate>   
                <a class="btn btn-deloitte" (click)="saveStructure()"> Save</a>
            </ng-template>
        <kendo-grid-column field="question.questionKey" title="Question key"></kendo-grid-column>
        <kendo-grid-column title="Question order" width="110">
            <ng-template kendoGridCellTemplate let-dataItem>
                <kendo-textbox-container [style.width]="'100%'" [style.padding-top]="'0 !important'">
                    <input kendoTextBox [(ngModel)]="dataItem.question.order" />
                </kendo-textbox-container>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="Title name">
            <ng-template kendoGridCellTemplate let-dataItem>

                <kendo-dropdownlist [filterable]="true" (filterChange)="handleFilter($event)" [defaultItem]="emptyTitle" [data]="titles"
                    [textField]="'name'" [(ngModel)]="dataItem.title" [valueField]="'name'" [style.width]="'100%'">
                    <ng-template kendoDropDownListNoDataTemplate>
                        <div>
                            No data found.
                            <ng-container *ngIf="filter">Do you want to create the new title - '{{ filter }}' ?</ng-container>
                            <br />
                            <button *ngIf="filter" class="k-button" (click)="addNew()">Add new title</button>
                        </div>
                    </ng-template>
                </kendo-dropdownlist>

            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="Title parent">
            <ng-template kendoGridCellTemplate let-dataItem>

                <kendo-dropdownlist [filterable]="true" (filterChange)="handleFilter($event)" [defaultItem]="emptyTitle" [data]="titles"  
                    [textField]="'name'" [(ngModel)]="dataItem.title.parentTitle" [valueField]="'name'" [style.width]="'100%'">
                    <ng-template kendoDropDownListNoDataTemplate>
                        <div>
                            No title found.
                            <ng-container *ngIf="filter">Do you want to create the new title - '{{ filter }}' ?</ng-container>
                            <br />
                            <button *ngIf="filter" class="k-button" (click)="addNew()">Add new title</button>
                        </div>
                    </ng-template>
                </kendo-dropdownlist>

            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="Title order" width="110">
            <ng-template kendoGridCellTemplate let-dataItem>
                <kendo-textbox-container [style.width]="'100%'">
                    <input class="k-textbox" [(ngModel)]="dataItem.title.order" kendoTextBox />
                </kendo-textbox-container>
            </ng-template>
        </kendo-grid-column>
    </kendo-grid>

</div>