﻿export class AppOverviewModel {
    AppId:string;
    Id: string;
    Name: string;
    Icon: string;
    AppType: string;
    IsOffered: boolean;
    IsVisibile: boolean;
    Url: string;
    AlternateUrl: string;
}