﻿import { Injectable } from "@angular/core";

import { CurrentUserModel } from "../models/current-user";
import { DataService } from "./data.service";
import { UrlPath } from "../common/url-path";
import { Observable } from 'rxjs';

//TODO implement this along with security
@Injectable({
    providedIn: 'root'
})
export class CurrentUserService {
    private _currentUser: CurrentUserModel;
    public get currentUser(): CurrentUserModel {
        if (!this._currentUser) {
            this.refresh();
        }
        return this._currentUser;
    }
    public set currentUser(value: CurrentUserModel) {
        this._currentUser = value;
    }

    public getUserInfo(): Observable<CurrentUserModel> {
        return this.dataService.get<CurrentUserModel>(UrlPath.loginApiGetCurrentUser);
    }

    constructor(private readonly dataService: DataService) { }

    refresh() {
        this.dataService.get<CurrentUserModel>(UrlPath.loginApiGetCurrentUser).subscribe(data => this.currentUser = data);
    }
}