import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../../../../shared/services/data.service';
import { UrlPath } from '../../../../shared/common/url-path';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ActivatedRoute, Router } from '@angular/router';
import { JurisdictionAndTopicModel, JurisdictionPermissionModel, TopicPermissionModel } from '../../../../shared/models/knowledgehub/Jurisdiction-topic.model';
import { AlertService } from '../../../../shared/services/alert.service';
import { ListSurveyPermissionComponent } from '../list-survey-permission/list-survey-permission.component';
import { CurrentUserModel } from '../../../../shared/models/current-user';
import { CurrentUserService } from '../../../../shared/services/current-user-service';
import { FormGroup, Validators, FormControl } from '@angular/forms';
@Component({
    selector: 'itp-app-survey-permission',
    templateUrl: './app-survey-permission.component.html',
    styleUrls: ['../list-apps/list-apps.component.scss']
})
export class AppSurveyPermissionComponent implements OnInit {
    @ViewChild(ListSurveyPermissionComponent) permissionList: ListSurveyPermissionComponent;
    SelectedJurisdictions: JurisdictionPermissionModel[];
    SelectedTopics: TopicPermissionModel[];
    organizationName: string;
    topicKey: string;
    data: unknown;
    constructor(readonly dataService: DataService,
        private readonly route: ActivatedRoute,
        private alertServe: AlertService,
        private currentUserService: CurrentUserService,
        private router: Router) { }
    permissionModel: JurisdictionAndTopicModel;

    surveyPermissionForm = new FormGroup({
        surveyTopics: new FormControl(''),
        surveyCountries: new FormControl('', Validators.required)
    });

    ngOnInit() {
        if (this.route.snapshot.paramMap.get('topicKey') == '' || this.route.snapshot.paramMap.get('topicKey') == "new") {
            this.topicKey = '';
        }
        else {
            this.topicKey = this.route.snapshot.paramMap.get('topicKey');
        }
        let key = this.topicKey == '' ? 'new' : this.topicKey;
        let currentUser: CurrentUserModel;
        this.currentUserService.getUserInfo().subscribe(data => {

            currentUser = data;
            this.organizationName = currentUser.organizationName;
            this.dataService.get<JurisdictionAndTopicModel>
                (UrlPath.getSurveyPermissionModel(this.organizationName, key)).subscribe(data => {                   
                    this.permissionModel = data;
                    console.log(this.permissionModel);
                    this.SelectedJurisdictions = this.permissionModel.jurisdictions.filter(jurisdiction => jurisdiction.hasAccess);
                    this.SelectedTopics = this.permissionModel.topics.filter(topic => topic.hasAccess);
                });
        });
    }

    save() {
        let permissionModel = { Jurisdictions: this.SelectedJurisdictions, Topics: this.SelectedTopics, ClientName: this.organizationName };
        this.dataService.post(UrlPath.saveSurveyPermission(), permissionModel)
            .subscribe(data => { this.SelectedJurisdictions = null, this.SelectedTopics = null; this.router.navigate(['admin/apps/surveypermissionoverview']) });
    }

}
