import { Component, OnInit } from "@angular/core";
import { UrlPath } from "../../../../shared/common/url-path";
import { DataService } from "../../../../shared/services/data.service";
import { CurrentUserService } from "../../../../shared/services/current-user-service";
import { CurrentUserModel } from "../../../../shared/models/current-user";
import { DataGridService } from "../../../../shared/services/data-grid.service";

@Component({
    selector: 'survey-titles',
    templateUrl: './survey-titles.component.html',
    styleUrls: ['./survey-titles.component.scss']
})
export class SurveyTitlesComponent implements OnInit{
    constructor(readonly dataService: DataService, readonly dataGridService: DataGridService, readonly currentUserService: CurrentUserService) { }
    data: any;

    ngOnInit(){
        let currentUser: CurrentUserModel;
        this.currentUserService.getUserInfo().subscribe(data => {
         currentUser = data;
         let username = {UserName: currentUser.emailId};
        this.dataService.get<any>(UrlPath.getTopics()).subscribe(data => {
            this.data = data;
            console.log(this.data);
            
        });
        });
    }

    addHeaderStructure(){
        return UrlPath.titles_create();
    }

    editTitleStructure(topicKey: string): string{
        return UrlPath.titles_edit(topicKey);
    }
}