﻿<itp-panel>
    <div class="container-fluid" *ngIf="data">
        <form #appPermissionForm="ngForm" class="form-horizontal" name="appPermissionForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
                <h4 style="font-size: 1.5em; margin-bottom: 1em;" class="col-md-4"> Manage App Permissions </h4>
            </div>
            <div class="form-group">
                <label class="col-md-2 control-label">App Name:</label>
                <div class="col-sm-2">
                    <select class="form-control" id="app" [(ngModel)]="selectedApps" name="app" (change)="changeApp()">
                        <option *ngFor="let app of data.application" [value]="app?.appId">{{app?.name}}</option>
                    </select>
                </div>
            </div>
            <div class="form-group">
                <label class="col-md-2 control-label">Functionality:</label>
                <div class="col-sm-2">
                    <select class="form-control" id="functionality" [(ngModel)]="selectedAppFunctionalities" name="functionality">
                        <option *ngFor="let functionality of data.functionality" [value]="functionality?.id">{{functionality?.functionality}}</option>
                    </select>
                </div>
            </div>
            <div class="form-group">
                <label class="col-md-2 control-label">Jurisdictions:</label>
                <div class="col-sm-2">                    
                    <kendo-multiselect [data]="data.jurisdictions" [(ngModel)]="selectedJurisdictions"
                                       class="form-control" name='jurisdictions' [textField]="'name'" [valueField]="'jurisdictionId'"
                                       id="jurisdiction"></kendo-multiselect>
                </div>
            </div>
            <div class="form-group">
                <label class="col-md-2 control-label"></label>
                <div class="col-sm-6" style="display:inline">                    
                    <button type="submit" style="display:inline" [disabled]="!appPermissionForm.form.valid" class="btn-deloitte btn">Submit</button>
                </div>
            </div>
        </form>
    </div>

</itp-panel>