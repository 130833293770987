import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UrlPath } from '../../../../shared/common/url-path';
import { DataService } from '../../../../shared/services/data.service';
import { DataGridService } from '../../../../shared/services/data-grid.service';
import { FileHelper } from 'app/shared/Helpers/file.helper';
import { AlertService } from 'app/shared/services/alert.service';
import { orderBy, filterBy, SortDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { PageChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';

@Component({
    selector: 'list-archive-apps',
    templateUrl: './list-archive-apps.component.html',
    styles: []
})
export class ListArchiveAppDocumentsComponent implements OnInit {
    @Input() canModifyPermissions: boolean;
    @Input() appId: string;
    @Output() onEditClick = new EventEmitter<any>();
    @Output() onAppNameLoaded = new EventEmitter<any>();
    public gridView: GridDataResult = {
        data: [],
        total: 0
      };
      pageSize = 10;
      public filter: FilterDescriptor;
      public sort: SortDescriptor[] = [{
        field: 'Period',
        dir: 'asc'
      }];
      public skip = 0;
    constructor(readonly dataService: DataService, readonly dataGridService: DataGridService, readonly alertService: AlertService) { }
    data: any;
    ngOnInit() {
        this.canModifyPermissions = true;

    }

    public LoadArchiveAppDocuments(appId: string) {
        this.dataService.get<any>(UrlPath.getAllArchiveAppDocuments(appId)).subscribe(t => {
            this.data = t.archiveAppDocuments;
            this.gridView = {
                data: this.data.slice(this.skip, this.skip + this.pageSize),
                total: this.data.length
              } 
              this.gridView = {
                data:orderBy(this.data, this.sort),
                total: this.data.length
              } 
            this.onAppNameLoaded.emit(t.appName);
        });
    }

    createNewIndustry(): string {
        return UrlPath.editArchiveAppDocument("new");
    }

    public sortChange(sort: SortDescriptor[]): void {
        this.sort = sort;
        this.gridView = {
          data:orderBy(this.data.slice(this.skip, this.skip + this.pageSize), this.sort),
          total: this.data.length
        } 
      }

      public pageChange(event: PageChangeEvent): void {
        this.skip = event.skip;
        this.LoadArchiveAppDocuments(this.appId);
    }
    
      public filterChange(filter: FilterDescriptor): void{
        this.filter= filter;
        this.gridView = {
          data: filterBy(this.data.slice(this.skip, this.skip + this.pageSize), this.filter),
          total: this.data.length
        }
      }

    editArchiveAppDocumentLink(dataItem: any) {
        this.onEditClick.emit(dataItem);
    }
    remove(dataItem: any) {
        this.dataService.get(UrlPath.deleteArchiveAppDocument(dataItem.id)).subscribe(data => {
            this.LoadArchiveAppDocuments(this.appId);
        });
    }

    async downloadFiles(blobId: string) {
        await FileHelper.downloadFile(blobId, "", this.dataService, this.alertService);

    }
}
