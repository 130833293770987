﻿export class MenuItemModel {
    menuItemId: string;
    parentMenuItemId: string;
    name: string;
    url: string;
    viewOrder: number;
    image: number;
    hoverImage: number;
    children: MenuItemModel[];
    Roles : string;
}