﻿export class LegalEntityDetailModel {
          legalEntityId : string;
          companyId : string;
          legalEntityCode : string;
          legalEntityName : string;
          jurisdictionId : string;
          industrieIds : string[];
          legalEntityForm : string;
          dateOfIncorporation : Date;
          dateDissolution : Date;
          tradingName : string;
          address : string;
          localTaxNumber : string;
          contactName : string;
          contactPersonEmail : string;
          contactPersonCapacity : string;
          directShipmentsPossible : string;
          importExportPossible : string;
          privateUseOfCompanyAssets : string;
          bankAccountNumber : string;
          bankName : string;
          bankContactDetails: string; 
          IBANCode : string;
          SWIFTBICCode : string;
          isVisible : boolean;
          startFiscalYear : string;
          validFrom : Date;
          validTill : Date;
          salesType : string;
          contactDetailsVATAuthoritiesInCountryOfResidence : string;
}  