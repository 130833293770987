﻿import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { GridModule } from '@progress/kendo-angular-grid';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';

import { SharedModule } from '../../shared/shared.module';
import { routing } from "./industries.routing";

import { IndustriesOverviewComponent } from "./components/overview/overview.component";
import { ListIndustriesComponent } from "./components/list-industries/list-industries.component";
import { CreateIndustryComponent } from "./components/create-industry/create-industry.component";
import { EditIndustryComponent } from "./components/edit-industry/edit-industry.component";

@NgModule({
    declarations: [
        IndustriesOverviewComponent,
        ListIndustriesComponent,
        CreateIndustryComponent,
        EditIndustryComponent
    ],
    imports: [
        routing,
        FormsModule,
        SharedModule,
        GridModule,
        DialogModule,
        DropDownsModule,
        CommonModule
    ]
})
export class IndustriesModule { }