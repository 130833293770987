import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { CurrentUserService } from '../services/current-user-service';
import { CurrentUserModel } from '../models/current-user';
import { MenuSharedService } from '../services/menu.shared.service';
import { MenuItemModel } from '../models/menu-item';
@Injectable({
    providedIn: 'root'
})
export class AdminActivatorGuard implements CanActivate {
    constructor(private userInfoService: CurrentUserService, private router: Router, private activatedRoute: ActivatedRoute, private menuService: MenuSharedService) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        let user = await this.userInfoService.getUserInfo().toPromise() as CurrentUserModel;

        if (user === null || user === undefined || user.roles === undefined || user.roles.length === 0) {
            this.router.navigate(['Error/Unauthorized']);
            return false;
        }
        else {
            this.menuService.getMenus().subscribe(data => {

                let currentMenu = this.getCurrentMenu(state, data);
                //If there is no current menu or parent menu, redirect to un authorized access page
                if (!currentMenu) {
                    this.router.navigate(['Error/Unauthorized']);
                    return false;
                }
                //If Menu exists but menu has no roles defined, that means that menu is accessbile to every one
                if (!currentMenu.Roles) {
                    return true;
                }

                //If there are no roles common between menu roles and user roles, redirect to unauthorized page
                if (!this.findCommonElements(currentMenu.Roles.split(';'), user.roles)) {
                    this.router.navigate(['Error/Unauthorized']);
                    return false;
                }
            });
        }
        return true;
    }

    // Iterate through each element in the 
    // first array and if some of them  
    // include the elements in the second 
    // array then return true. 
    findCommonElements(arr1: any, arr2: any) {
        return arr1.some((item: any) => arr2.includes(item))
    }



    private getCurrentMenu(state: RouterStateSnapshot, parentMenus: MenuItemModel[]): MenuItemModel {
        for (const item of parentMenus) {
            const result = `#${state.url}`.indexOf(item.url) !== -1 && item.url != '#/' ? item : this.getCurrentMenu(state, item.children);
            if (result) return result;
        }
    }
}
