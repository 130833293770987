<kendo-upload
      [saveUrl]="uploadSaveUrl"
      [removeUrl]="remove"
      [autoUpload]="true"
      [multiple]="false"
      [restrictions]="alowedTypes"
      (success)="onUploadSuccess($event)"
              (error)="errorEventHandler($event)"
              [(ngModel)]="files"
      >
    </kendo-upload>