<div style="margin-left: 4rem; margin-right: 4em;">
    <h4 style="margin-bottom: 1em;  font-size: 1.5em;">Survey Titles Management</h4>
    
<kendo-grid [data]="data?.topicsWithTitles"
            [pageSize]="dataGridService.state.take"
            [skip]="dataGridService.state.skip"
            [sort]="dataGridService.state.sort"
            [sortable]="true"
            [pageable]="dataGridService.pageable"
            [groupable]="false"
            [scrollable]="'scrollable'"
            [filter]="dataGridService.state.filter"
            filterable="menu"
            [columnMenu]="true"
            [resizable]="true"
            [reorderable]="true"
            [loading]="false"
            (dataStateChange)="dataGridService.dataStateChange($event)">

    <ng-template kendoGridToolbarTemplate>   
        <a class="btn btn-deloitte" [attr.href]="addHeaderStructure()"> Add</a>
    </ng-template>

    <kendo-grid-column field="topicName" title="Topic"></kendo-grid-column>
    <kendo-grid-command-column title="" width="250">
        <ng-template kendoGridCellTemplate let-dataItem>
            <a class="btn btn-deloitte" style="width: 150px;" [attr.href]="editTitleStructure(dataItem.topicKey)">Edit titles structure</a>
        </ng-template>
    </kendo-grid-command-column>
</kendo-grid>

</div>