﻿import { Component, OnInit } from "@angular/core";

import { DocumentPath } from "../../shared/common/document-path";

@Component({
    selector: "itp-footer",  
    templateUrl: "./layout-footer.component.html"
})
export class LayoutFooterComponent implements OnInit {
    privacyStatementPath = DocumentPath.privacyStat;
    year: number;

    ngOnInit(): void {
        this.year = new Date().getFullYear();
    }
}