
<base href="/" />
<itp-spinner [style.display]="loadingService.loading ? '' : 'none'"></itp-spinner>
<div id="header" *ngIf="router.url !== '/internal-login'">
    <itp-header></itp-header>
</div>

<div toastContainer></div>
<div appNoRightClick>
    <div class="d-flex" id="wrapper">
        <div id="left-nav" class="bg-light" *ngIf="router.url !== '/internal-login'">
            <itp-left-nav></itp-left-nav>
        </div>

        <div id="page-content" class="page-content-wrapper bg-light">

            <div id="content" style="min-height: 85vh">
                <router-outlet></router-outlet>
            </div>
            <div class="row margin-bottom-lg footer" id="footer" *ngIf="router.url !== '/internal-login'">
                <div class="col-md-12 col-xs-12">
                    <itp-footer></itp-footer>
                </div>
            </div>
        </div>


    </div>
</div>

