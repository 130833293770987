﻿import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class LoadingService {
    loading = false;

    hidingUrls: string[] = [];

    addHidingUrl(urlPath: string): void {
        this.hidingUrls.push(urlPath);
    }

    removeHidingUrl(urlPath: string): void {
        this.hidingUrls = this.hidingUrls.filter(e => e !== urlPath);
    }
}