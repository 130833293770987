﻿<div style="padding-right: 3em; padding-left: 2.2em;">
    <h4 style="font-size: 1.5em; margin-bottom: 1em;">Apps Management</h4>
    <div *ngIf="data">
        <remove-item (delete)="remove($event)"></remove-item>
        <kendo-grid [data]="gridView"                           
            [sort]="sort" [sortable]="true" (sortChange)="sortChange($event)"
            [groupable]="false" [scrollable]="'scrollable'"
            [columnMenu]="true" [resizable]="true" [reorderable]="true"
            (edit)="editHandler($event)"
            (add)="addHandler()" (remove)="removeHandler($event.dataItem)">

            <ng-template kendoGridToolbarTemplate>
                <a class="btn btn-deloitte" [attr.href]="addNewAppLink()">Add new</a>
            </ng-template>
            <kendo-grid-column field="appType.name" title="Type" width="200">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <a [attr.href]="appLink(dataItem)">{{dataItem?.appType?.name}}</a>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="name" title="Name" width="200">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <a [attr.href]="appLink(dataItem)">{{dataItem.name}}</a>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="description" title="Description" width="200">
                <ng-template kendoGridCellTemplate let-dataItem>
                    {{dataItem.description}}
                </ng-template>
            </kendo-grid-column>
            <!-- <kendo-grid-column field="Url" title="Url" width="150">
        <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.url}}
        </ng-template>
    </kendo-grid-column> -->
            <!-- <kendo-grid-column field="AlternateUrl" title="AlternateUrl" width="150">
        <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.alternateUrl}}
        </ng-template>
    </kendo-grid-column> -->
            <kendo-grid-column field="isWhiteRoles" title="Accessible roles" width="85">
            </kendo-grid-column>
            <kendo-grid-column field="isGreyRoles" title="Unaccessible roles" width="85">
            </kendo-grid-column>
            <kendo-grid-command-column title="" width="250">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <a class="btn btn-deloitte" style="width: 60px;" [attr.href]="editAppLink(dataItem.appId)">Edit</a>
                    <a class="btn btn-deloitte" style="background-color: #97999B; color: white; width: 60px;"
                        (click)="showDeleteContentModal(dataItem)">Delete</a>
                    <a class="btn btn-deloitte" *ngIf="dataItem.appType.name==='Archive App'" style="width: 100px;"
                        [attr.href]="manageDocuments(dataItem.appId)">Documents</a>
                </ng-template>
            </kendo-grid-command-column>
        </kendo-grid>

    </div>
</div>