﻿<itp-panel>
    <div class="container-fluid" *ngIf="data && companies && industries">
        <form #legalEntityForm="ngForm" class="form-horizontal" name="legalEntityForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
                <label class="col-md-2 control-label">Company:</label>
                <div class="col-sm-6">
                    <kendo-dropdownlist [data]="companies" [(ngModel)]="data.companyId" name="companies"
                        class="form-control col-md-10 " [textField]="'companyName'" [valuePrimitive]="true" [valueField]="'companyId'"
                        id="companyId">
                    </kendo-dropdownlist>
                </div>
            </div>

            <div class="form-group">
                <label class="col-md-2 control-label">Code:</label>
                <div class="col-sm-6">
                    <input type="text" class="form-control col-md-10" #title="ngModel"
                        [(ngModel)]="data.legalEntityCode" name="Code">
                </div>
            </div>

            <div class="form-group">
                <label class="col-md-2 control-label">Name:</label>
                <div class="col-sm-6">
                    <input type="text" class="form-control col-md-10" #title="ngModel"
                        [(ngModel)]="data.legalEntityName" name="Name">
                </div>
            </div>

            <div class="form-group">
                <label class="col-md-2 control-label">Form:</label>
                <div class="col-sm-6">
                    <input type="text" class="form-control col-md-10" #title="ngModel"
                        [(ngModel)]="data.legalEntityForm" name="Form">

                </div>
            </div>

            <div class="form-group">
                <label class="col-md-2 control-label">Jurisdictions:</label>
                <div class="col-sm-6">

                    <kendo-dropdownlist [data]="jurisdictions" [(ngModel)]="data.jurisdictionId" name="jurisdictions"
                        class="form-control col-md-10 " [textField]="'name'" [valuePrimitive]="true" [valueField]="'jurisdictionId'"
                        id="jurisdictionId">
                    </kendo-dropdownlist>
                </div>
            </div>

            <div class="form-group">
                <label class="col-md-2 control-label">Industries:</label>
                <div class="col-sm-6">
                    <kendo-multiselect style="height: 100%;" [data]="industries" [(ngModel)]="data.industrieIds" name="industries"
                        class="form-control col-md-10 " [textField]="'name'" [valuePrimitive]="true" [valueField]="'id'" id="industries"
                        [autoClose]="false"></kendo-multiselect>
                </div>
            </div>

            <!-- <div class="form-group">
                <label class="col-md-2 control-label">Industries</label>
                <div class="col-sm-6">
                        <input type="text" class="form-control col-md-10" #title="ngModel" [(ngModel)]="data.industries" name="Industries"
                        required>

                </div>
            </div>            -->

            <div class="form-group">
                <label class="col-md-2 control-label">Date of Incorporation:</label>
                <div class="col-sm-6">
                    <kendo-datepicker [format]="'yyyy-MM-dd'"  name="DateOfIncorporation" [(ngModel)]="data.dateOfIncorporation">
                    </kendo-datepicker>
                </div>
            </div>

            <div class="form-group">
                <label class="col-md-2 control-label">Date of dissolution:</label>
                <div class="col-sm-6">
                    <kendo-datepicker [format]="'yyyy-MM-dd'" name="DateDissolution" [(ngModel)]="data.dateDissolution">
                    </kendo-datepicker>
                </div>
            </div>

            <div class="form-group">
                <label class="col-md-2 control-label">Trading name:</label>
                <div class="col-sm-6">
                    <input type="text" class="form-control col-md-10" #title="ngModel" [(ngModel)]="data.tradingName"
                        name="TradingName">
                </div>
            </div>

            <div class="form-group">
                <label class="col-md-2 control-label">Address:</label>
                <div class="col-sm-6">
                    <input type="text" class="form-control col-md-10" #title="ngModel" [(ngModel)]="data.address"
                        name="Address">
                </div>
            </div>

            <div class="form-group">
                <label class="col-md-2 control-label">Local tax number:</label>
                <div class="col-sm-6">
                    <input type="text" class="form-control col-md-10" #title="ngModel" [(ngModel)]="data.localTaxNumber"
                        name="LocalTaxNumber">
                </div>
            </div>

            <div class="form-group">
                <label class="col-md-2 control-label">Contact name:</label>
                <div class="col-sm-6">
                    <input type="text" class="form-control col-md-10" #title="ngModel" [(ngModel)]="data.contactName"
                        name="ContactName">
                </div>
            </div>

            <div class="form-group">
                <label class="col-md-2 control-label">Contact email:</label>
                <div class="col-sm-6">
                    <input type="text" class="form-control col-md-10" #title="ngModel"
                        [(ngModel)]="data.contactPersonEmail" name="ContactPersonEmail">
                </div>
            </div>

            <div class="form-group">
                <label class="col-md-2 control-label">Contact capacity:</label>
                <div class="col-sm-6">
                    <input type="text" class="form-control col-md-10" #title="ngModel"
                        [(ngModel)]="data.contactPersonCapacity" name="ContactPersonCapacity">
                </div>
            </div>

            <div class="form-group">
                <label class="col-md-2 control-label">Direct shipments possible:</label>
                <div class="col-sm-6">
                    <input type="text" class="form-control col-md-10" #title="ngModel"
                        [(ngModel)]="data.directShipmentsPossible" name="DirectShipmentsPossible">

                </div>
            </div>

            <div class="form-group">
                <label class="col-md-2 control-label">Import export possible:</label>
                <div class="col-sm-6">
                    <input type="text" class="form-control col-md-10" #title="ngModel"
                        [(ngModel)]="data.importExportPossible" name="ImportExportPossible">
                </div>
            </div>

            <div class="form-group">
                <label class="col-md-2 control-label">Private use of company assets:</label>
                <div class="col-sm-6">
                    <input type="text" class="form-control col-md-10" #title="ngModel"
                        [(ngModel)]="data.privateUseOfCompanyAssets" name="PrivateUseOfCompanyAssets">
                </div>
            </div>

            <div class="form-group">
                <label class="col-md-2 control-label">Bank account number:</label>
                <div class="col-sm-6">
                    <input type="text" class="form-control col-md-10" #title="ngModel"
                        [(ngModel)]="data.bankAccountNumber" name="BankAccountnumber">
                </div>
            </div>

            <div class="form-group">
                <label class="col-md-2 control-label">Bank name:</label>
                <div class="col-sm-6">
                    <input type="text" class="form-control col-md-10" #title="ngModel" [(ngModel)]="data.bankName"
                        name="BankName">
                </div>
            </div>

            <div class="form-group">
                <label class="col-md-2 control-label">Bank contact details:</label>
                <div class="col-sm-6">
                    <input type="text" class="form-control col-md-10" #title="ngModel"
                        [(ngModel)]="data.bankContactDetails" name="BankContactDetails">
                </div>
            </div>

            <div class="form-group">
                <label class="col-md-2 control-label">IBAN Code:</label>
                <div class="col-sm-6">
                    <input type="text" class="form-control col-md-10" #title="ngModel" [(ngModel)]="data.IBANCode"
                        name="IBANCode">
                </div>
            </div>

            <div class="form-group">
                <label class="col-md-2 control-label">SWIFT BIC Code:</label>
                <div class="col-sm-6">
                    <input type="text" class="form-control col-md-10" #title="ngModel" [(ngModel)]="data.SWIFTBICCode"
                        name="SWIFTBICCode">
                </div>
            </div>

            <div class="form-group">
                <label class="col-md-2 control-label">Start fiscal year:</label>
                <div class="col-sm-6">
                    <input type="text" class="form-control col-md-10" #title="ngModel"
                        [(ngModel)]="data.startFiscalYear" name="StartFiscalYear">
                </div>
            </div>

            <div class="form-group">
                <label class="col-md-2 control-label">Sales type:</label>
                <div class="col-sm-6">
                    <kendo-dropdownlist [data]="salesTypes" [(ngModel)]="data.salesType" name="salesTypes"
                        class="form-control col-md-10" [valuePrimitive]="true" textField="name" valueField="id" id="salesTypes">
                    </kendo-dropdownlist>
                </div>
            </div>

            <div class="form-group">
                <label class="col-md-2 control-label">Contact details VAT authorities in country of residence:</label>
                <div class="col-sm-6">
                    <input type="text" class="form-control col-md-10" #title="ngModel"
                        [(ngModel)]="data.contactDetailsVATAuthoritiesInCountryOfResidence"
                        name="ContactDetailsVATAuthoritiesInCountryOfResidence">
                </div>
            </div>

            <div class="form-group">
                <label class="col-md-2 control-label"></label>
                <div class="col-sm-6" style="display:inline">
                    <button type="submit" style="display:inline" [disabled]="!legalEntityForm.form.valid"
                        class="btn-deloitte btn">Submit</button>
                    <!--<div *ngIf="loading" style="display:inline;margin:10px">
                        <i class="fa fa-spinner fa-spin"></i>
                    </div>-->
                </div>
            </div>
        </form>
    </div>
</itp-panel>