﻿import { settings } from '../../../environments/environment';

export class UrlPath {
  
   
    private static basePath = `${settings.baseUrl}`;
    static baseNgPath = `${UrlPath.basePath}#`;
    static random = () => `${Math.floor((Math.random() * 1000000) + 1)}`;
    static signOut = `${UrlPath.basePath}Home/LogOut`;
    static noAccess = `${UrlPath.basePath}Home/NoAccess`;
    static home = `${UrlPath.basePath}Home`;
    static app = (appId: string) => `${UrlPath.baseNgPath}/apps/${appId}`;
    static news = `${UrlPath.baseNgPath}/news`;
    static news_see = (newsId: string) => `${UrlPath.news}/${newsId}`;
    static news_create = (newsId: string) => `${UrlPath.news}/${newsId}`;
    
    static clients = `${UrlPath.baseNgPath}/admin/clients`;
    static clients_create = `${UrlPath.clients}/new`;
    static clients_edit = (clientId: string) => `${UrlPath.clients}/edit/${clientId}`;

    static surveyPermission = `${UrlPath.baseNgPath}/admin/apps/surveypermissionoverview`;

    static roles_getRoles = () => `${UrlPath.apiBasePath}role/getroles`;

    static legalEntities = (clientId: string) => `${UrlPath.baseNgPath}/admin/legal-entities/c/${clientId}`;
    static legalEntities_create = (clientId: string) => `${UrlPath.legalEntities(clientId)}/new`;
    static legalEntities_edit = (clientId: string, legalEntityId: string) => `${UrlPath.legalEntities(clientId)}/${legalEntityId}/edit`;

    static titles = () => `${UrlPath.baseNgPath}/admin/surveys/titles`;
    static titles_create = () => `${UrlPath.titles()}/new`;
    static titles_edit = (topicKey: string) => `${UrlPath.titles()}/${topicKey}`;
    // static subtitles_edit = (clientId: string, legalEntityId: string) => `${UrlPath.legalEntities(clientId)}/${legalEntityId}/edit`;


    static countriesOfVATEstablishment = (legalEntityId: string) => `${UrlPath.baseNgPath}/admin/vat-countries/l/${legalEntityId}`;
    static countriesOfVATEstablishment_create = (legalEntityId: string) => `${UrlPath.countriesOfVATEstablishment(legalEntityId)}/new`;
    static countriesOfVATEstablishment_edit = (legalEntityId: string, vatCountryId: string) => `${UrlPath.countriesOfVATEstablishment(legalEntityId)}/${vatCountryId}/edit`;

    static reportingCountries = (vatCountryId: string) => `${UrlPath.baseNgPath}/reporting-countries/${vatCountryId}`;
    static reportingCountry_createNew = (vatCountryId: string, vatCountryName: string) => `${UrlPath.reportingCountries}/${vatCountryId}/${vatCountryName}/new`;
    static reportingCountry_edit = (reportingCountryId: string) => `${UrlPath.reportingCountries}/${reportingCountryId}/edit`;

    static admin = `${UrlPath.baseNgPath}/admin`;
    static admin_apps = `${UrlPath.admin}/apps`;
    
    static admin_apps_addNewApp = `${UrlPath.admin_apps}/new`;
   
    static admin_apps_editApp = (appId: string) => `${UrlPath.admin_apps}/edit/${appId}`;
    static admin_industriesApi_edit = (id: string) => `${UrlPath.admin_industriesApi}/edit/${id}`;

    static apiBasePath = `${settings.baseUrl}api/`;

    static loginApiGetCurrentUser = `${UrlPath.apiBasePath}CurrentUser/GetUserInfo`;
    static getLogoutUrl = `${UrlPath.apiBasePath}CurrentUser?logout=true`;


    static appTypeApi = `${UrlPath.apiBasePath}AppType`;

    static admin_appApi = `${UrlPath.apiBasePath}AdminApp`;
    static admin_appApi_getAll = `${UrlPath.admin_appApi}/GetAdminApps`;

    static admin_industriesApi = `${UrlPath.apiBasePath}Industry`;

    static getIndustries = (id: string) => `${UrlPath.apiBasePath}Industry`;

    

    static admin_appApi_getEditModel = (appId: string) => `${UrlPath.admin_appApi}/GetEditModel?appId=${appId}`;
    static getSurveyPermissionModel = (clientId: string, topicKey: string) => `${UrlPath.apiBasePath}PermissionSurvey?clientName=${clientId}&topicKey=${topicKey}`;

    static admin_appApi_createApp = `${UrlPath.admin_appApi}/CreateApp`;
    static admin_appApi_editApp = `${UrlPath.admin_appApi}/EditApp`;
    static admin_appApi_deleteApp = (appId: string) => `${UrlPath.admin_appApi}?appId=${appId}`;

    static newsApi = `${UrlPath.apiBasePath}News`;
    static newsApi_getEditModel = (newsId: string) => `${UrlPath.newsApi}/GetEditModel/${newsId}`;
    static newsApi_edit = (newsId: string) => `${UrlPath.newsApi}/Edit/${newsId}`;
    static newsApi_delete = (id: string) => `${UrlPath.newsApi}?id=${id}`;

    static clientsApi = `${UrlPath.apiBasePath}Client`;
    static clientsApi_getEditModel = (clientId: string) => `${UrlPath.clientsApi}/GetEditModel/?entityId=${clientId}`;
    static clientsApi_getEditViewModel = (clientId: string) => `${UrlPath.clientsApi}/GetEditViewModel/?entityId=${clientId}`;
    static clientsApi_create = `${UrlPath.clientsApi}/Create`;
    static clientsApi_edit = `${UrlPath.clientsApi}/Edit`;
    static clientsApi_delete = (id: string) => `${UrlPath.clientsApi}/Delete/?entityId=${id}`;

    static surveyChangesApi = `${UrlPath.apiBasePath}SurveyChanges`;
    static surveyChangesApi_getChanges = () => `${UrlPath.surveyChangesApi}/getSurveyChanges`;

    static legalEntitiesApi = `${UrlPath.apiBasePath}LegalEntity`;
    static legalEntitiesApi_getByClient = (clientId: string) => `${UrlPath.legalEntitiesApi}/?clientId=${clientId}`;
    static legalEntitiesApi_getEditModel = (legalEntityId: string) => `${UrlPath.legalEntitiesApi}/GetEditModel/?entityId=${legalEntityId}`;
    static legalEntitiesApi_create = `${UrlPath.legalEntitiesApi}/Create`;
    static legalEntitiesApi_edit = `${UrlPath.legalEntitiesApi}/Edit`;
    static legalEntitiesApi_delete = (id: string) => `${UrlPath.legalEntitiesApi}/Delete/?entityId=${id}`;

    static countriesOfVATEstablishmentApi = `${UrlPath.apiBasePath}/CountryOfVATEstablishment`;
    static countriesOfVATEstablishmentApi_getByLegalEntity = (legalEntityId: string) => `${UrlPath.countriesOfVATEstablishmentApi}/GetByLegalEntity/?legalEntityId=${legalEntityId}`;
    static countriesOfVATEstablishmentApi_getEditModel = (countryOfVATEstablishmentId: string) => `${UrlPath.countriesOfVATEstablishmentApi}/GetEditModel/?entityId=${countryOfVATEstablishmentId}`;
    static countriesOfVATEstablishmentApi_create = `${UrlPath.countriesOfVATEstablishmentApi}/Create`;
    static countriesOfVATEstablishmentApi_edit = `${UrlPath.countriesOfVATEstablishmentApi}/Edit`;
    static countriesOfVATEstablishmentApi_delete = (id: string) => `${UrlPath.countriesOfVATEstablishmentApi}/Delete/?entityId=${id}`;

    static getMenus = (userId: string) => `${UrlPath.apiBasePath}MenuItem?userId=${userId}`;
    static saveMenu = `${UrlPath.apiBasePath}MenuItem`;

    static getJurisdictions = (userId: string) => `${UrlPath.apiBasePath}Jurisdiction`;

    static getApps = (userId: string) => `${UrlPath.apiBasePath}App`;
    static getNews = (userId: string) => `${UrlPath.apiBasePath}News?username=${userId}`;
    static getTopics = () => `${UrlPath.apiBasePath}survey`;
    static getPurchasedTopics = () => `${UrlPath.apiBasePath}surveyTitle/PurchasedTopics`;
    static importFromITX = () => `${UrlPath.apiBasePath}survey/importFromITX`;
    static getSurveyResult = () => `${UrlPath.apiBasePath}SurveyResult/GetSurveyResults`;
    static createExcelData = () => `${UrlPath.apiBasePath}SurveyResult/CreateSurveysExcel`;
    
    static surveyUrl = (topicId: string) => `${UrlPath.basePath}#/survey/${topicId}`;
    static surveyOverview = () => `${UrlPath.basePath}#/survey/contacts`;
    static surveyNews = () => `${UrlPath.basePath}#/survey/newsItems`;

    static surveyUrlWithDate = (topicId: string, from: string, until: string) => `/survey/${topicId}/${from}/${until}`;
    static surveyRouteWithDate = (topicId: string, from: string, until: string, countryCode: string) => `${UrlPath.basePath}#/survey/${topicId}/${from}/${until}#${countryCode}`;
    static surveyRouterLinkChanges = (topicId: string, countryName: string) => `${UrlPath.basePath}#/survey/${topicId}#${countryName}`;
    static surveyRouterLink = (topicId: string) => `/survey/${topicId}`;
    static surveyChanges = () => `${UrlPath.basePath}#/survey-overview/changes`;
    static getSurveySummary = () => `${UrlPath.apiBasePath}SurveySummary`;
    static saveSurveyPermission = () => `${UrlPath.apiBasePath}PermissionSurvey`;
    static DownloadITXSurveyData = () => `${UrlPath.apiBasePath}SurveyPermissionOverview/DownloadITXSurveyData`;
    static getAllSurveyPermissions = () => `${UrlPath.apiBasePath}surveypermissionoverview/GetAll`;
    static editSurveyPermission = (topicKey: string) => `${UrlPath.basePath}#/admin/apps/surveypermission/${topicKey}`;
    static deleteSurveyPermission = () => `${UrlPath.apiBasePath}PermissionSurvey/DeleteSurveyPermission`;

    static getAllArchiveAppDocuments = (appId: string) => `${UrlPath.apiBasePath}ArchiveAppDocument?archiveAppId=${appId}`;
    static getArchiveAppDocumentDetails = (appId: string) => `${UrlPath.apiBasePath}ArchiveAppDocument?ArchiveAppDocumentId=${appId}`;
    static ManageArchiveAppDocuments = () => `${UrlPath.apiBasePath}ArchiveAppDocument`;
    static editArchiveAppDocument = (appId: string) => `${UrlPath.basePath}#/admin/apps/archiveappdocument/${appId}`;
    static deleteArchiveAppDocument = (appId: string) => `${UrlPath.apiBasePath}ArchiveAppDocument?id=${appId}`;
    static uploadFile: any = `${UrlPath.apiBasePath}Upload`;
    static getFileContent = (blobRepositKey: string) => `${UrlPath.apiBasePath}FileInformation?blobRepositId=${blobRepositKey}`;
    static getArchiveAppDocumentOverview= (appId: string) => `${UrlPath.basePath}#/archiveappdocuments/${appId}`;

    static saveAdmin_appApi = () => `${UrlPath.apiBasePath}AdminApp`;
    static editAdmin_appApi = (appKey: string) => `${UrlPath.basePath}#/AdminApp/${appKey}`;

    static getCreateNewsViewModel = (newsId: string) => `${UrlPath.apiBasePath}News?newsId=${newsId}`;
    static getAllNews = (newsId: string) => `${UrlPath.apiBasePath}News?id=${newsId}`

    static company = `${UrlPath.baseNgPath}/Company`;
    static company_details = (companyId: string) => `${UrlPath.company}/${companyId}`;

    static legalEntity = `${UrlPath.baseNgPath}/legal-entity`;
    static legalEntity_details = (legalEntityId: string) => `${UrlPath.legalEntity}/${legalEntityId}`;

    static establishment_by_legalentity = (legalEntityId: string) => `${UrlPath.baseNgPath}/Establishment/${legalEntityId}`;
    static establishment_details = (legalEntityId: string, establishmentId: string) => `${UrlPath.baseNgPath}/Establishment/${legalEntityId}/${establishmentId}`;
    
    static reportingCountry = `${UrlPath.baseNgPath}/ReportingCountry`;
    static reportingCountry_by_establishment = (establishmentId: string) => `${UrlPath.baseNgPath}/ReportingCountry/${establishmentId}`;
    static reportingCountry_details = (establishmentId: string, reportingCountryId: string) => `${UrlPath.reportingCountry}/${establishmentId}/${reportingCountryId}`;    
 

    static companyApi = `${UrlPath.apiBasePath}Company`;
    static companyApi_getAll = () => `${UrlPath.companyApi}/GetAll`;
    static companyApi_getCompanyDetails = (companyId: string) => `${UrlPath.companyApi}/GetDetails/?companyId=${companyId}`;
    static companyApi_deleteCompany = (id: string) => `${UrlPath.companyApi}/?id=${id}`;
    static companyApi_SaveCompanyDetails = () => `${UrlPath.companyApi}/SaveCompany`;

    static factsheet = `${UrlPath.baseNgPath}/FactsheetImportExport`;
    static factsheetApi = `${UrlPath.apiBasePath}FactsheetImportExport`;
    static SaveFactsheetExcelData = () => `${UrlPath.factsheetApi}/SaveFactsheetExcel`;
    static GetFactsheetExcelData = () => `${UrlPath.factsheetApi}/GetFactsheetData`;  
    static GetFactsheetErrorExcelData = () => `${UrlPath.factsheetApi}/GetErrorFactsheetData`;  

    static legalEntityApi = `${UrlPath.apiBasePath}LegalEntity`;
    static legalEntityApi_getAll = () => `${UrlPath.legalEntityApi}/GetAll`;
    static legalEntityApi_getLegalEntityDetails = (legalEntityId: string) => `${UrlPath.legalEntityApi}/GetDetails/?legalEntityId=${legalEntityId}`;
    static legalEntityApi_deleteLegalEntity = (id: string) => `${UrlPath.legalEntityApi}/?id=${id}`;

    static titlesApi = `${UrlPath.apiBasePath}Title`;
    static titlesApi_getAll = (topicId: string) => `${UrlPath.titlesApi}/GetAll/?topicKey=${topicId}`;
    static titlesApi_save = () => `${UrlPath.titlesApi}/SaveTitle`;
    static titlesApi_getQuestionKeys = (topicKey: string) => `${UrlPath.titlesApi}/GetAllQuestionKeysByTopicKey/?topicKey=${topicKey}`;



    static titleQuestionsApi = `${UrlPath.apiBasePath}TitleQuestion`;
    static titleQuestionsApi_getAll = (topicId: string) => `${UrlPath.titleQuestionsApi}/GetAll/?topicKey=${topicId}`;
    static titleQuestionsApi_saveAll = () => `${UrlPath.titleQuestionsApi}/SaveTitleQuestion`;
    
    // static subtitlesApi_getLegalEntityDetails = (legalEntityId: string) => `${UrlPath.subtitlesApi}/GetDetails/?legalEntityId=${legalEntityId}`;
    // static subtitlesApi_deleteLegalEntity = (id: string) => `${UrlPath.subtitlesApi}/?id=${id}`;

    static legalEntityApi_SaveLegalEntityDetails = () => `${UrlPath.legalEntityApi}/SaveLegalEntity`;

    static establishmentApi = `${UrlPath.apiBasePath}Establishment`;
    static establishmentApi_getAll = (legalEntityId: string) => `${UrlPath.establishmentApi}?legalentityId=${legalEntityId}`;
    static establishmentApi_getEstablishmentDetails = (establishmentId: string) => `${UrlPath.establishmentApi}/GetDetails/?establishmentId=${establishmentId}`;
    static establishmentApi_deleteEstablishment = (id: string) => `${UrlPath.establishmentApi}/?id=${id}`;
    static establishmentApi_SaveEstablishmentDetails = () => `${UrlPath.establishmentApi}/SaveEstablishment`;

    static reportingCountryApi = `${UrlPath.apiBasePath}ReportingCountry`;
    static reportingCountryApi_getAll = (establishmentId: string) => `${UrlPath.reportingCountryApi}?establishmentId=${establishmentId}`;
    static reportingCountryApi_getReportingCountryDetails = (reportingCountryId: string) => `${UrlPath.reportingCountryApi}/GetDetails/?reportingCountryId=${reportingCountryId}`;
    static reportingCountryApi_deleteReportingCountry = (id: string) => `${UrlPath.reportingCountryApi}/?id=${id}`;
    static reportingCountryApi_SaveReportingCountryDetails = () => `${UrlPath.reportingCountryApi}/SaveReportingCountry`;


    static appPermission = `${UrlPath.baseNgPath}/admin/apps/apppermission`;
    static appPermission_details = (appFunctionalityId: string) => `${UrlPath.appPermission}/${appFunctionalityId}`;

    static appPermissionApi = `${UrlPath.apiBasePath}AppPermission`;
    static appPermissionApi_getAll = () => `${UrlPath.appPermissionApi}/GetAll`;
    static appPermissionApi_getAppPermissionDetails = (appFunctionalityId: string) => `${UrlPath.appPermissionApi}/GetDetails/?appFunctionalityId=${appFunctionalityId}`;
    static appPermissionApi_deleteAppPermission = (appFunctionalityId: string) => `${UrlPath.appPermissionApi}/?appFunctionalityId=${appFunctionalityId}`;
    static appPermissionApi_SaveAppPermissionDetails = () => `${UrlPath.appPermissionApi}/SaveAppPermission`;
    static internalLoginApi = `${UrlPath.apiBasePath}InternalLogin`;
    static internalLoginGetUser = (email: string) => `${UrlPath.internalLoginApi}/GetUser/?email=${email}`;

}
