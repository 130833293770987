﻿import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { toDataSourceRequestString, translateDataSourceResultGroups, DataSourceRequestState } from "@progress/kendo-data-query";
import { GridDataResult, DataStateChangeEvent } from "@progress/kendo-angular-grid";

import { DataService } from "./data.service";

class GridResult {
    Data: any[];
    Total: number;
}

@Injectable()
export class DataGridService {
    urlPath: string;
    readUrlPath: string;
    putUrlPath: string;
    deleteUrlPath:string;
    data: GridDataResult;
    loading: boolean;
    state: DataSourceRequestState = {
        skip: 0,
        take: 10,
        aggregates: null,
        filter: null,
        group: null,
        sort: null,
    };
    pageable = {
        pageSizes: [10, 20, 50]
    };

    constructor(private readonly dataService: DataService) { }

    read(): void {
        this.loading = true;
        this.fetch(this.readUrlPath, this.state).subscribe(data => {
            this.data = data;
            this.loading = false;
        });
    }

    save(entity: any, isNew: boolean): Observable<GridDataResult> {
        this.loading = true;
        var subscription = isNew ? this.post(this.putUrlPath, entity) : this.put(this.putUrlPath, entity);
        subscription.subscribe(data => {
            this.data = data;
        });
        return subscription;
    }

    removeHandler(entity: any) {
        this.dataService.post<any>(this.deleteUrlPath, entity).subscribe(() => this.read());
    }

    removeById() {
        this.dataService.delete<any>(this.deleteUrlPath).subscribe(() => this.read());
    }

    dataStateChange(state: DataStateChangeEvent): void {
        this.state = ((state) as DataSourceRequestState);
        //this.read();
        this.fetch(this.urlPath, this.state).subscribe(data => this.data = data);
    }

    private fetch(urlPath: string, state: DataSourceRequestState): Observable<GridDataResult> {
        const queryStr = `${(urlPath.indexOf('?') > -1 ? '&' : '?')}${toDataSourceRequestString(state)}`;        
        const hasGroups = state.group && state.group.length;
        return this.addPipes(this.dataService.get<GridResult>(`${urlPath}${queryStr}`), hasGroups);
    }

    private put(urlPath: string, entity: any): Observable<GridDataResult> {
        const queryStr = `${toDataSourceRequestString(this.state)}`;
        return this.addPipes(this.dataService.put<GridResult>(`${urlPath}?${queryStr}`, entity));
    }

    private post(urlPath: string, entity: any): Observable<GridDataResult> {
        const queryStr = `${toDataSourceRequestString(this.state)}`;
        return this.addPipes(this.dataService.post<GridResult>(`${urlPath}?${queryStr}`, entity));
    }

    private addPipes(httpCall: Observable<GridResult>, hasGroups: number = 0): Observable<GridDataResult> {
        return httpCall
            .pipe(map(({ Data, Total }: GridResult) => ({
                data: Data,
                total: Total
            } as GridDataResult)))
            .pipe(map(({ data, total }: GridDataResult) =>
                ({
                    data: hasGroups ? translateDataSourceResultGroups(data) : data,
                    total
                } as GridDataResult)
            ));
    }
}