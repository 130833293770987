﻿<div id="applicationName" class="nav-row">
    <img [src]="deloitteLogo" id="logoDeloitte" alt="Logo Deloitte" />
    <span class="clientName">WELCOME, {{userfirstname}}</span>
    <a class="pull-right" (click)="logout()" id="logout">
        <span class="glyphicon glyphicon-off" style="font-size: 20px !important;" aria-hidden="true"></span>
    </a>
</div>




<!-- <div class="row" style="display: inline-flex;">
<div class="horizontal-grey-box" [ngClass]="{'headerWithWidth col-6' : notMobile}">
        <div class="title-text title">Welcome to the Indirect Tax Portal</div>
        <div class="title-text description">Home of all your indirect tax related information and analysis, just afew clicks away…</div>
    </div>

    <div *ngIf="notMobile" class="col-5">
        <img [src]="headerImage" id="headerImage" />
    </div>
    
</div> -->