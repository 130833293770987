import { Directive, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Directive({
  selector: '[appNoRightClick]'
})
export class NoRightClickDirective {
  constructor(private readonly route: ActivatedRoute, public readonly router: Router) { }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event: any) {
    console.log(location.pathname);
    if(location.pathname.includes("internal") || location.pathname.includes("Internal")){
      event.preventDefault();
    }
  }

  @HostListener('window:keydown', ['$event'])
  onKeyPress($event: KeyboardEvent) {
    if (($event.ctrlKey || $event.metaKey) && $event.keyCode == 67){
      if(location.pathname.includes("internal") || location.pathname.includes("Internal")){
              event.preventDefault();
    }
  }
  }
}