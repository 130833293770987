﻿import { Component, OnInit } from '@angular/core';

import { GridComponent } from "../../../shared/components/grid/grid.component";
import { DataGridService } from "../../../shared/services/data-grid.service";
import { AlertService } from "../../../shared/services/alert.service";

import { UrlPath } from "../../../shared/common/url-path";
import { LegalEntityOverviewModel } from "../../models/legal-entity-overview.model";
import { DataService } from '../../../shared/services/data.service';
import { SalesType } from '../../models/sales-type.model';
import { JurisdictionAndTopicModel, JurisdictionModel} from '../../../shared/models/knowledgehub/Jurisdiction-topic.model';
import { element } from '@angular/core/src/render3';


@Component({
    selector: 'legal-entity-overview',
    templateUrl: './legal-entity-overview.component.html',
    providers: [DataGridService]
})
export class LegalEntityOverviewComponent extends GridComponent<LegalEntityOverviewModel> implements OnInit {
    constructor(readonly dataGridService: DataGridService,
        readonly alertService: AlertService, readonly dataService: DataService
    ) {
        super(dataGridService, alertService);
    }

    jurisdictions: JurisdictionModel[];
    data: any;
    loading: boolean;
    salesType = SalesType.all;
    ngOnInit() {
        this.loadLegalEntities();
    }

    loadLegalEntities() {
        this.loading = true;
        this.dataService.get(UrlPath.legalEntityApi_getAll()).subscribe(data => {
            this.data = data;
            this.data.forEach((element: LegalEntityOverviewModel) => {
                element.salesType = this.salesTypeToString(element);
            });
            this.dataService.get<any> (UrlPath.getJurisdictions('')).subscribe(data => {
            this.jurisdictions = data.jurisdictions;
            this.data.forEach((element: LegalEntityOverviewModel) => {
                element.jurisdiction = this.jurisdictionIdToString(element);
            });
        });
            this.loading = false;
        });
    }

    salesTypeToString(model: LegalEntityOverviewModel): string{
        return this.salesType[model.salesType].name;
    }

    jurisdictionIdToString(model: LegalEntityOverviewModel): string{
        return this.jurisdictions.find(x => x.jurisdictionId == model.jurisdictionId).name;
    }

    protected New(): LegalEntityOverviewModel {
        var model = new LegalEntityOverviewModel();
        return model;
    }

    addLegalEntity(): string {
        return UrlPath.legalEntity_details('new');
    }

    editLegalEntity(legalEntityId: string): string {
        return UrlPath.legalEntity_details(legalEntityId);
    }

    getEstablishments(legalEntityId: string): string {
        return UrlPath.establishment_by_legalentity(legalEntityId);
    }

    remove(legalEntityId: string) {
        this.removeUrl = UrlPath.legalEntitiesApi_delete(legalEntityId);
        this.loading = true;
        this.dataService.delete(UrlPath.legalEntityApi_deleteLegalEntity(legalEntityId)).subscribe(t => {
            
            this.alertService.success('Success', 'Deleted Successfully');
            this.loadLegalEntities();
        });
    }
}