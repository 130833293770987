﻿import { Component } from "@angular/core";

import { RoutePath } from "../../shared/common/route-path";
import { UrlPath } from "../../shared/common/url-path";
import { ImagePath } from "../../shared/common/image-path";


@Component({
    selector: "itp-left-nav",
    templateUrl: "./layout-left-nav.component.html",
    styleUrls: ["./layout-left-nav.component.scss"], 
})
export class LayoutLeftNavComponent {
    deloitteLogo = ImagePath.deloitteLogo;
    togglerImage = ImagePath.togglerImage;
    count: number;
    routePath = RoutePath;
    urlPath = UrlPath;
    sidebarPinned = false;
    sidebarExpended = false;

    constructor() { }

    public toggleSidebar(e: any) {
        e.preventDefault();
        this.sidebarPinned = !this.sidebarPinned;
        this.sidebarExpended = this.sidebarPinned;
    }

    public mouseEnterSideBar() {
        if (this.sidebarPinned) return;
        this.sidebarExpended = true;
    }

    public mouseLeaveSideBar() {
        if (this.sidebarPinned) return;
        this.sidebarExpended = false;
    }
}