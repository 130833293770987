﻿import { Component } from '@angular/core';

import { DataService } from "../../../../shared/services/data.service";
import { UrlPath } from "../../../../shared/common/url-path";
import { AppCreationResult } from "../../../../shared/models/apps/new-app.model";
import { CreateClientModel } from "../../../../shared/models/clients/create-client.model";

@Component({
    selector: 'create-client',
    templateUrl: './create-client.component.html',
    providers: [DataService]
})
export class CreateClientComponent {
    name: string;

    constructor(private readonly dataService: DataService) { }

    create() {
        var model = <CreateClientModel>{
            Name: this.name
        };

        this.dataService.post<AppCreationResult>(UrlPath.clientsApi_create, model).subscribe((result: AppCreationResult) => {
            window.location.href = UrlPath.clients;
        });
    }
}