import { Component } from '@angular/core';
import { Router } from "@angular/router";

import { ImagePath } from './shared/common/image-path';
import { LoadingService } from "./shared/services/loading.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = 'itp';
    titleImage = ImagePath.titleImage;
    togglerImage = ImagePath.togglerImage;
    sidebarPinned = false;
    sidebarExpended = false;
    constructor(public readonly router: Router,
        public readonly loadingService: LoadingService) { }

   
    public toggleSidebar(e: any) {
        e.preventDefault();
        this.sidebarPinned = !this.sidebarPinned;
        this.sidebarExpended = this.sidebarPinned;
    }

    public mouseEnterSideBar() {
        if (this.sidebarPinned) return;
        this.sidebarExpended = true;
    }

    public mouseLeaveSideBar() {
        if (this.sidebarPinned) return;
        this.sidebarExpended = false;
    }
}
