import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { RoutePath } from "./shared/common/route-path";
import { NotAuthorizedComponent } from './errors/not-authorized.component';
import { IncorrectOrganizationComponent } from './errors/incorrect-organization.component';
import { InternalLoginComponent } from './internal-login/internal-login.component';
import { AuthenticatedRouteActivator } from './shared/guards/authenticated-activator.guard';
import { AdminActivatorGuard } from './shared/guards/admin-activator.guard';
import { SurveyActivatorGuard } from './shared/guards/survey-activator.guard';

const routes: Routes = [
    { path: RoutePath.admin, loadChildren: "./admin/admin.module#AdminModule" },
    { path: RoutePath.adminNews, loadChildren: "./news/news.module#NewsModule",canActivate: [AdminActivatorGuard] },
    { path: RoutePath.company, loadChildren: "./company/company.module#CompanyModule", canActivate: [AdminActivatorGuard] },
    { path: RoutePath.establishment, loadChildren: "./establishments/establishment.module#EstablishmentModule", canActivate: [AdminActivatorGuard] },
    { path: RoutePath.reportingCountry, loadChildren: "./reportingCountry/reportingCountry.module#ReportingCountryModule", canActivate: [AdminActivatorGuard] },
    { path: RoutePath.surveyOverview, loadChildren: "./survey-overview/survey-overview.module#SurveyOverviewModule",canActivate: [SurveyActivatorGuard] },
    { path: RoutePath.survey, loadChildren: "./survey/survey.module#SurveyModule",canActivate: [SurveyActivatorGuard] },
    { path: RoutePath.adminLegalEntities, loadChildren: "./legal-entity/legal-entity.module#LegalEntityModule", canActivate: [AuthenticatedRouteActivator] },
    { path: RoutePath.archiveAppDocuments, loadChildren: "./archive-apps/archive-apps.module#ArchiveAppDocumentsModule", canActivate: [AuthenticatedRouteActivator] },
    { path: RoutePath.appPermission, loadChildren: "./admin/apps/apps.module#AppsModule", canActivate: [AuthenticatedRouteActivator] },
    { path: RoutePath.factsheetImportExport, loadChildren: "./factsheet-upload/factsheet.module#FactsheetModule", canActivate: [AuthenticatedRouteActivator] },
    { path: '', loadChildren: "./landing-page/landing-page.module#LandingPageModule", canActivate: [AuthenticatedRouteActivator] },
    { path: RoutePath.internalLogin, component: InternalLoginComponent },
    { path: 'Error/Unauthorized', component: NotAuthorizedComponent },
    { path: 'Error/IncorrectOrganization', component: IncorrectOrganizationComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        onSameUrlNavigation: 'reload',
        useHash: true, // Necessary for IE
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
        scrollOffset: [0, 64]
    })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
