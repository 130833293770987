﻿import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { DataService } from "../../../../shared/services/data.service";
import { UrlPath } from "../../../../shared/common/url-path";
import { AppCreationResult } from "../../../../shared/models/apps/new-app.model";
import { CreateCountryOfVATEstablishmentModel } from "../../../../shared/models/countries-of-vat-establishments/create-country-of-vat-establishment.model";
import { EditLegalEntityModel } from "../../../../shared/models/legal-entities/edit-legal-entity.model";

@Component({
    selector: 'create-vat-country',
    templateUrl: './create-vat-country.component.html',
    providers: [DataService]
})
export class CreateVatCountryComponent implements OnInit {
    legalEntityId: string;
    legalEntity: EditLegalEntityModel;
    countryId: string;
    loading: boolean;

    constructor(private readonly dataService: DataService,
        private readonly route: ActivatedRoute) { }


    ngOnInit() {
        this.legalEntityId = this.route.snapshot.paramMap.get('legalEntityId');
        this.get();
    }

    get() {
        this.loading = true;
        this.dataService.get<EditLegalEntityModel>(UrlPath.legalEntitiesApi_getEditModel(this.legalEntityId)).subscribe(model => {
            this.legalEntity = model;
            this.loading = false;
        });
    }

    create() {
        var model = <CreateCountryOfVATEstablishmentModel>{
            LegalEntityId: this.legalEntityId,
            CountryId: this.countryId
        };

        this.dataService.post<AppCreationResult>(UrlPath.countriesOfVATEstablishmentApi_create, model).subscribe((result: AppCreationResult) => {
            window.location.href = UrlPath.countriesOfVATEstablishment(this.legalEntityId);
        });
    }
}