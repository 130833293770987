﻿<itp-panel>
    <div class="container-fluid">
        <form class="form-horizontal" name="IndustryForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
                <h4 style="font-size: 1.5em; margin-bottom: 1em;" class="col-md-4"> Manage Industries </h4>
            </div>
            <div class="form-group">
                <label class="col-md-2 control-label">Name:</label>
                <div class="col-sm-6">
                    <input type="text" class="form-control col-md-10" [(ngModel)]="industryName"
                        name="name" required>
                </div>
            </div>
            <div class="form-group">
                <label class="col-md-2 control-label"></label>
                <div class="col-sm-6" style="display:inline">
                    <button type="submit" style="display:inline" class="btn-deloitte btn">Submit</button>
                </div>
            </div>
        </form>
    </div>

    <list-industries (onEditClick)="editIndustry($event)" (onAddClick)="addIndustry()"></list-industries>


</itp-panel>