﻿import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { GridComponent } from "../../../../shared/components/grid/grid.component";
import { DataGridService } from "../../../../shared/services/data-grid.service";
import { AlertService } from "../../../../shared/services/alert.service";
import { UrlPath } from "../../../../shared/common/url-path";
import { IndustryDto } from "../../../../shared/models/industries/industryDto.model";
import { Observable } from 'rxjs';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { map } from 'rxjs/operators';
import { DataService } from '../../../../shared/services/data.service';

@Component({
    selector: 'list-industries',
    templateUrl: './list-industries.component.html',
    providers: [DataGridService]
})
export class ListIndustriesComponent extends GridComponent<IndustryDto> implements OnInit {
    
    constructor(readonly dataService: DataService,
        readonly alertService: AlertService, private formBuilder: FormBuilder,readonly dataGridService:DataGridService) {
            super(dataGridService, alertService);
    }
    @Output() onEditClick = new EventEmitter<any>();
    @Output() onAddClick = new EventEmitter<any>();
    ngOnInit() {

    }
    data: IndustryDto[];
    public LoadIndustries() {
        this.dataService.get<any>(UrlPath.admin_industriesApi).subscribe(data => {
            this.data = data;
        });
    }

    ediIndustry(dataItem: any) {
        this.onEditClick.emit(dataItem);
    }
    addNewIndustry()
    {
        this.onAddClick.emit();
    }
    protected New(): IndustryDto {
        var model = new IndustryDto();
        return model;
    }
}

