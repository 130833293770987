import { Component, OnInit, Input } from '@angular/core';
import { GridComponent } from '../../../../shared/components/grid/grid.component';
import { DataGridService } from '../../../../shared/services/data-grid.service';
import { AlertService } from '../../../../shared/services/alert.service';
import { SurveyPermissionModel } from '../../../../shared/models/apps/survey-overview.model';
import { UrlPath } from '../../../../shared/common/url-path';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../../../../shared/services/data.service';
import { CurrentUserModel } from '../../../../shared/models/current-user';
import { CurrentUserService } from '../../../../shared/services/current-user-service';
import { TopicPermissionModel } from '../../../../shared/models/knowledgehub/Jurisdiction-topic.model';
import { FileHelper } from '../../../../shared/Helpers/file.helper';

@Component({
    selector: 'list-survey-permission',
    templateUrl: './list-survey-permission.component.html',
    styleUrls: ['./list-survey-permission.component.scss']
})
export class ListSurveyPermissionComponent extends GridComponent<SurveyPermissionModel> implements OnInit {
    data: any;
    constructor(readonly dataGridService: DataGridService,
        readonly dataService: DataService,
        readonly alertService: AlertService,
        private readonly route: ActivatedRoute,
        readonly currentUserService: CurrentUserService) {
        super(dataGridService, alertService);
    }
    private clientId: string;
    private publicUser: any;
    private isImportingBtnEnabled = true;
    topics: TopicPermissionModel[] = [];
    @Input() canModifyPermissions: boolean;
    ngOnInit() {
        this.canModifyPermissions = true;
        this.loadPermissions();
    }

    loadPermissions() {
        let currentUser: CurrentUserModel;
        this.currentUserService.getUserInfo().subscribe(data => {
            currentUser = data;
            this.publicUser = data;
            let username = { ClientName: currentUser.organizationName };
            this.dataService.post(UrlPath.getAllSurveyPermissions(), username).subscribe(data => {
                console.log(data);
                this.data = data;
            });
        });
    }

    importDataFromITX() {
        this.isImportingBtnEnabled = false;
        const emptyJson = {};
        this.dataService.put(UrlPath.importFromITX(), emptyJson).subscribe(data => {
            this.isImportingBtnEnabled = true;
            this.alertService.success('Synchronization started', 'The latest ITX items are being pulled into ITP')            
        });
    }
    



    protected New(): SurveyPermissionModel {
        var model = new SurveyPermissionModel();
        return model;
    }
    editPermissionLink(app: SurveyPermissionModel): string {
        return UrlPath.editSurveyPermission(app.topic.topicKey);
    }

    addNewPermissionLink(): string {
        return UrlPath.editSurveyPermission("new");
    }

    remove(survey: SurveyPermissionModel): void {
        const topic: TopicPermissionModel =
        {
            topicId: null,
            topicName: survey.topic.topicName,
            topicKey: survey.topic.topicKey,
            isSelected: true,
            hasAccess: true
        };
        this.topics.push(topic);
            const permissionModel = { Jurisdictions: '', Topics: this.topics, ClientName: this.publicUser.organizationName };
            this.dataService.put(UrlPath.deleteSurveyPermission(), permissionModel)
                .subscribe((data) => {
                    this.loadPermissions();
                    this.topics.pop();
                });
    }
    downlodDataFromITX() {
        window.location.href = UrlPath.DownloadITXSurveyData();
        this.alertService.info('Download started', 'File download has started, please wait while it finishes.');
    }
    
}
