﻿<div>
    <div id="wrapper" [ngClass]="{ 'sidebar-enabled': sidebarExpended }">
        <div id="sidebar-wrapper">
    
            <div>
                <layout-menu id="layout-menu"></layout-menu>
            </div>
        </div>            
    </div>
    
</div>
