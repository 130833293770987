﻿<ul class="sidebar-nav nav-pills nav-stacked" (mouseover)="open()" (mouseleave)="close()">
    <div style="max-height: 418px">
        <div style="position: relative; top: 0; left: 0;">
            <a style="display: flex">
                <span [ngClass]="{'visibility: hidden' : !isToggled}" id="menuToggler" class="glyphicon glyphicon-triangle-left"></span>
                <img [src]="togglerImage" [ngClass]="{'marginleft': !isToggled}" class="menuAnchorPadding" id="togglerImage" alt="toggler" (click)="toggleMenu()" />
            </a>
            <li *ngFor="let menuItem of items"  style="transition-property: visibility;
            transition-duration: 0.30s;">
            <div (click)="menuItem.url ? underfined : menuItem.isCollapsed = !menuItem.isCollapsed">
                <a [attr.href]="menuItem.url ? menuItem.url : undefined" [ngStyle]="menuItem.style" class="menuAnchorPadding">
                    <span class="separator" style="margin-left: 10px"></span>
                    <span class="content">{{ menuItem.item.name }}</span>
                    <span *ngIf="menuItem.children.length > 0" class="submenuFold glyphicon parentMenuGlyph" [ngClass]="{'glyphicon-chevron-down' : !menuItem.isCollapsed, 'glyphicon-chevron-up' : menuItem.isCollapsed }"></span>
                </a>
            </div>  
                <div *ngIf="menuItem.isCollapsed">
                    <a [attr.href]="item.item.url ? item.item.url : undefined" style="margin-left: 60px;" class="hovered" *ngFor="let item of menuItem.children">
                        {{item.item.name}}
                    </a>
                </div>
            
        </li>
        </div>
    </div>
</ul>