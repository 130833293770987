﻿import { OnInit, ViewChild } from '@angular/core';
import { Component } from '@angular/core';

import { DataService } from "../../../../shared/services/data.service";
import { UrlPath } from "../../../../shared/common/url-path";
import { AppTypeModel } from "../../../../shared/models/apps/app-type.model";
import { AppCreationResult } from "../../../../shared/models/apps/new-app.model";
import { CreateIndustryModel } from "../../../../shared/models/industries/create-industry.model";
import { ListIndustriesComponent } from '../list-industries/list-industries.component';
import { IndustryDto } from '../../../../shared/models/industries/industryDto.model';

@Component({
    selector: 'create-industry',
    templateUrl: './create-industry.component.html',
    providers: [DataService]
})
export class CreateIndustryComponent implements OnInit {

    @ViewChild(ListIndustriesComponent) listIndustriesComponent: ListIndustriesComponent;

    ngOnInit(): void {
        this.listIndustriesComponent.LoadIndustries();
    }

    industryName: string;
    existingIndustryId: string;
    constructor(private readonly dataService: DataService) { }

    public onSubmit() {
        var model = <CreateIndustryModel>{
            Name: this.industryName,
            Id: this.existingIndustryId
        };


        this.dataService.post<any>(UrlPath.admin_industriesApi, model).subscribe((result) => {
            this.industryName = '';
            this.listIndustriesComponent.LoadIndustries();
        });
    }

    public editIndustry(industry: IndustryDto) {
        this.industryName = industry.name;
        this.existingIndustryId = industry.id;
    }

    public addIndustry() {
        this.industryName = '';
        this.existingIndustryId = null;
    }
}