﻿import { Component, OnInit } from '@angular/core';

import { DataService } from "../../../../../shared/services/data.service";
import { UrlPath } from "../../../../../shared/common/url-path";
import { AppPermissionDetailsModel } from "../models/app-permission-details.model";
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';



@Component({
    selector: 'app-permission-details',
    templateUrl: './app-permission-details.component.html',
    providers: [DataService],
    styleUrls: ['../../list-apps/list-apps.component.scss']
})
export class AppPermissionDetailsComponent implements OnInit {

    appFunctionalityId: string;
    selectedApps: any;
    selectedJurisdictions: any;
    selectedAppFunctionalities: any;
    data: any;
    allFunctionalities: any;
    id: string;

    constructor(private readonly route: ActivatedRoute, private readonly dataService: DataService) { }

    ngOnInit() {
        if (this.route.snapshot.paramMap.get('appFunctionalityId') == '' || this.route.snapshot.paramMap.get('appFunctionalityId') == "new") {

            this.appFunctionalityId = '';
        }
        else {
            this.appFunctionalityId = this.route.snapshot.paramMap.get('appFunctionalityId');
        }
        this.loadAppPermissions();

    }

    loadAppPermissions() {
       
        this.dataService.get<any>(UrlPath.appPermissionApi_getAppPermissionDetails(this.appFunctionalityId)).subscribe(data => {
            this.data = data;
            this.allFunctionalities = this.data.functionality;  

            this.data.functionality = this.allFunctionalities.filter((x: any) => x.appId === this.selectedApps);
            
            if (this.appFunctionalityId != '' && data && data.appPermissionOverviewDTO) {
            let existingJurisdictions = data.appPermissionOverviewDTO.jurisdictions ? data.appPermissionOverviewDTO.jurisdictions.split(';') : [];
            this.selectedJurisdictions = this.data.jurisdictions.filter((value: any) => -1 !== existingJurisdictions.indexOf(value.name));

                this.selectedApps = this.data.application.filter((x: any) => x.appId === data.appPermissionOverviewDTO.appId)[0].appId;
                this.data.functionality = this.allFunctionalities.filter((x: any) => x.appId === this.selectedApps);
                this.selectedAppFunctionalities = this.data.functionality.filter((x: any) => x.id === data.appPermissionOverviewDTO.appFunctionalityId)[0].id;
                       
            }
        });
    }

    changeApp() {
        this.data.functionality = this.allFunctionalities.filter((x: any) => x.appId === this.selectedApps);
    }

    public virtual: any = {
        itemHeight: 28
    };

    onSubmit() {
        var model = <AppPermissionDetailsModel>{
            AppFunctionalityId: this.selectedAppFunctionalities,
            Jurisdictions: this.selectedJurisdictions,
       
        };
        if (this.id != '') {
            model.Id = this.data.appPermissionOverviewDTO.id;
        };
        
        this.dataService.post(UrlPath.appPermissionApi_SaveAppPermissionDetails(), model).subscribe(() => {
            window.location.href = UrlPath.appPermission;
        });
    }
}