﻿<itp-panel>
    <div style="margin-right: 3em; margin-left: 2.2em;">
        <h4 style="font-size: 1.5em; margin-bottom: 1em;">Admin - Edit app</h4>
        <div class="card col-7">
            <div class="card-body p-4">
                <div class="form-group">
                    <h3 class="card-title mb-4" style="font-size: 1.2rem;">Edit App</h3>
                </div>

                <form [formGroup]="appForm">
                    <div class="form-group">
                        <label>Type:</label>
                        <select class="form-control" id="appType" formControlName="appType" >
                            <option *ngFor="let appType of appTypes" [value]="appType?.id">{{appType?.name}}</option>
                        </select>


                    </div>

                    <div class="form-group">
                        <label>Name:</label>
                        <input  class="form-control" formControlName="appName" />
                    </div>

                    <div class="form-group">
                        <label>Description:</label>
                        <textarea id="appDescription"  class="form-control"
                            formControlName="appDescription"></textarea>
                    </div>

                    <div class="form-group">
                        <label>Is visible?</label>
                        <div>
                            <p-inputSwitch name="isVisible" formControlName="appIsVisible"></p-inputSwitch>
                                                

                        </div>

                    </div>

                    <div class="form-group">
                        <label>Is offered?</label>
                        <div>
                            <p-inputSwitch name="isVisible" formControlName="appIsOffered"></p-inputSwitch>

                        </div>

                    </div>

                    <div class="form-group">
                        <label>White roles:</label>
                        <kendo-multiselect
                        class="form-control"
                        [data]="roles?.roles"
                        formControlName="appIsWhiteRoles"></kendo-multiselect>                    
                    </div>

                    <div class="form-group">
                        <label>Grey roles:</label>
                        <kendo-multiselect
                        class="form-control"
                        [data]="roles?.roles"
                        formControlName="appIsGreyRoles"></kendo-multiselect>                    
                    </div>

                    <div *ngIf="appForm.controls.appType && appForm.controls.appType?.value != archiveAppId"  class="form-group">
                        <label>Url:</label>
                        <input  class="form-control" formControlName="appUrl" />
                    </div>

                
                        


                    <div *ngIf="appForm.controls.appType && appForm.controls.appType?.value != archiveAppId" class="form-group">
                        <label>Alternate url:</label>
                        <input class="form-control" formControlName="appAlternateUrl" />
                    </div>

                
                    <div class="form-group" *ngIf="app">
                            <label>Thumbnail image:</label>
                    <!-- <div class="input-group">
                        <div class="input-group-prepend">
                          <button class="btn btn-outline-secondary" (click)="uploadImage()" style="margin-right: 1px;" type="button">Upload</button>
                        </div> -->
                        <file-upload [files]="existingFile" [uploadSaveUrl]="uploadSaveUrl" [alowedTypes]="alowedTypes" (fileUploaded)="isUploaded($event)"></file-upload>
                        <!-- <div class="btn btn-outline-secondary" style="margin-left: 1px;" type="button">Browse</div> -->

                      <!-- </div> -->
                    </div>


                    <!-- 
                    <div class="form-group">
                        <label class="col-md-2 control-label" for="appType">App Type</label>
                        <div class="col-sm-6">
                            <kendo-dropdownlist [data]="appTypes" [(ngModel)]="selectedAppType"
                                class="form-control col-md-10" [ngModelOptions]="{standalone: true}"
                                [textField]="'Name'" [valueField]="'Id'" id="appType"></kendo-dropdownlist>
                        </div>
                    </div> -->



                    <!-- Linked app -->
                    <!-- <div class="form-group"
                        *ngIf="selectedAppType && (selectedAppType.Name == 'Linked App' || selectedAppType.Name == 'News App')">
                        <label class="col-md-2 control-label" for="appUrl">Url</label>
                        <div class="col-sm-6">
                            <input id="appUrl" required class="form-control" [(ngModel)]="appUrl"
                                [ngModelOptions]="{standalone: true}" />
                        </div>
                    </div>

                    <div class="form-group" *ngIf="selectedAppType && selectedAppType.Name == 'News App'">
                        <label class="col-md-2 control-label" for="appContent">Content</label>
                        <div class="col-sm-6">
                            <textarea id="appContent" required class="form-control" [(ngModel)]="appContent"
                                [ngModelOptions]="{standalone: true}"></textarea>
                        </div>
                    </div> -->

                    <div class="form-group">
                        <button type="submit" style=" float:right;" class="btn-deloitte btn" [disabled]="!appForm.valid"
                            (click)="save()">Save</button>
                    </div>
                </form>


            </div>
        </div>
    </div>
</itp-panel>