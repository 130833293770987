import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CurrentUserService } from '../services/current-user-service';
import { CurrentUserModel } from '../models/current-user';

@Injectable({
    providedIn: 'root'
})
export class AuthenticatedRouteActivator implements CanActivate {
    constructor(private userInfoService: CurrentUserService, private router: Router) { }

    async canActivate(route: ActivatedRouteSnapshot) {

        let user = await this.userInfoService.getUserInfo().toPromise() as CurrentUserModel;
        if (user === null || user === undefined || user.roles === undefined || user.roles.length === 0) {
            this.router.navigate(['Error/Unauthorized']);
            return false;
        }
        return true;
    }

}
