﻿import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { GridModule } from '@progress/kendo-angular-grid';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';

import { SharedModule } from '../../shared/shared.module';
import { routing } from "./clients.routing";

import { ClientsOverviewComponent } from "./components/overview/overview.component";
import { ListClientsComponent } from "./components/list-clients/list-clients.component";
import { CreateClientComponent } from "./components/create-client/create-client.component";
import { EditClientComponent } from "./components/edit-client/edit-client.component";

@NgModule({
    declarations: [
        ClientsOverviewComponent,
        ListClientsComponent,
        CreateClientComponent,
        EditClientComponent
    ],
    imports: [
        routing,
        FormsModule,
        SharedModule,
        GridModule,
        DialogModule,
        DropDownsModule,
        CommonModule
    ]
})
export class ClientsModule { }