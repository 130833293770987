﻿import { DataGridService } from "../../services/data-grid.service";
import { AlertService } from "../../services/alert.service";

export abstract class GridComponent<T> {
    public editDataItem: T;
    public isNew: boolean;
    protected readUrl: string;
    protected putUrl: string;
    protected removeUrl: string;
    constructor(public readonly dataGridService: DataGridService,
                protected readonly alertService: AlertService) { }

    protected abstract New(): T;

    protected read() {
        this.dataGridService.readUrlPath = this.readUrl;
        this.dataGridService.putUrlPath = this.putUrl;
        this.dataGridService.deleteUrlPath = this.removeUrl;
        this.dataGridService.read();
    }

    public addHandler() {
        this.editDataItem = this.New();
        this.isNew = true;
    }

    public editHandler({ dataItem }: any) {
        this.editDataItem = dataItem;
        this.isNew = false;
    }

    public cancelHandler() {
        this.editDataItem = undefined;
    }

    public saveHandler(entity: T) {
        this.dataGridService.save(entity, this.isNew);
        this.editDataItem = undefined;
        this.read();
    }


    public removeHandler(entity: T) {
        this.dataGridService.loading = true;
        this.dataGridService.deleteUrlPath = this.removeUrl;
        this.dataGridService.removeHandler(entity);
    }

    errorHandler(error: any) {        
        this.dataGridService.loading = false;
        this.alertService.error("error", "d");
    }
}