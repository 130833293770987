﻿<itp-panel>

    <div class="container-fluid">
        <div class="row pull-right">
            <button class="btn btn-primary" (click)="save()">Save</button>
        </div>
        <div class="row" *ngIf="!model">loading...</div>
        <form class="form-horizontal" name="form" *ngIf="model">

            <div class="form-group">
                <label class="col-md-2 control-label" for="Name">Client Name</label>
                <div class="col-sm-6">
                    <input id="Name" required class="form-control" [(ngModel)]="model.Name"
                           [ngModelOptions]="{standalone: true}" />
                </div>
            </div>

            <div class="form-group">
                <label class="col-md-2 control-label" for="ContactName">Contact Name</label>
                <div class="col-sm-2">
                    <input id="ContactName" required class="form-control" [(ngModel)]="model.ContactName"
                           [ngModelOptions]="{standalone: true}" />
                </div>
                <label class="col-md-2 control-label" for="ContactFunction">Contact Function</label>
                <div class="col-sm-2">
                    <input id="ContactFunction" required class="form-control" [(ngModel)]="model.ContactFunction"
                           [ngModelOptions]="{standalone: true}" />
                </div>
            </div>

            <div class="form-group">
                <label class="col-md-2 control-label" for="ContactOffice">Contact Office Address</label>
                <div class="col-sm-2">
                    <input id="ContactOffice" required class="form-control" [(ngModel)]="model.ContactOffice"
                           [ngModelOptions]="{standalone: true}" />
                </div>
                <label class="col-md-2 control-label" for="ContactEmail">Contact E-mail</label>
                <div class="col-sm-2">
                    <input id="ContactEmail" required class="form-control" [(ngModel)]="model.ContactEmail"
                           [ngModelOptions]="{standalone: true}" />
                </div>
            </div>

            <div class="form-group">
                <label class="col-md-2 control-label" for="ContactWork">Contact Work Number</label>
                <div class="col-sm-2">
                    <input id="ContactWork" required class="form-control" [(ngModel)]="model.ContactWork"
                           [ngModelOptions]="{standalone: true}" />
                </div>
                <label class="col-md-2 control-label" for="ContactMobile">Contact Mobile Number</label>
                <div class="col-sm-2">
                    <input id="ContactMobile" required class="form-control" [(ngModel)]="model.ContactMobile"
                           [ngModelOptions]="{standalone: true}" />
                </div>
            </div>

        </form>
    </div>


</itp-panel>