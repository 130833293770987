﻿import { Component, OnInit, Input } from '@angular/core';

import { GridComponent } from "../../../../shared/components/grid/grid.component";
import { DataGridService } from "../../../../shared/services/data-grid.service";
import { AlertService } from "../../../../shared/services/alert.service";

import { UrlPath } from "../../../../shared/common/url-path";
import { LegalEntityOverviewModel } from "../../../../shared/models/legal-entities/legal-entity-overview.model";

@Component({
    selector: 'list-legal-entities',
    templateUrl: './list-legal-entities.component.html',
    providers: [DataGridService]
})
export class ListLegalEntitiesComponent extends GridComponent<LegalEntityOverviewModel> implements OnInit {
    @Input() clientId: string;

    constructor(readonly dataGridService: DataGridService,
        readonly alertService: AlertService) {
        super(dataGridService, alertService);
    }

    ngOnInit() {
        this.readUrl = UrlPath.legalEntitiesApi_getByClient(this.clientId);
        this.putUrl = UrlPath.legalEntitiesApi;
        this.read();
    }

    protected New(): LegalEntityOverviewModel {
        var model = new LegalEntityOverviewModel();
        return model;
    }

    appLink(model: LegalEntityOverviewModel): string {
        return UrlPath.countriesOfVATEstablishment(model.Id);
    }

    addNewAppLink(): string {
        return UrlPath.legalEntities_create(this.clientId);
    }

    editAppLink(model: LegalEntityOverviewModel): string {
        return UrlPath.legalEntities_edit(this.clientId, model.Id);
    }

    remove(model: LegalEntityOverviewModel) {
        this.removeUrl = UrlPath.legalEntitiesApi_delete(model.Id);
        this.dataGridService.loading = true;
        this.dataGridService.deleteUrlPath = this.removeUrl;
        this.dataGridService.removeById();
    }
}