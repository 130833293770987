﻿import { Component, OnInit, ViewChild } from '@angular/core';

import { GridComponent } from "../../../../shared/components/grid/grid.component";
import { DataGridService } from "../../../../shared/services/data-grid.service";
import { AlertService } from "../../../../shared/services/alert.service";
import { ActivatedRoute } from '@angular/router';

import { UrlPath } from "../../../../shared/common/url-path";
import { AppOverviewModel } from "../../../../shared/models/apps/app-overview.model";
import { DataService } from '../../../../shared/services/data.service';
import { CurrentUserModel } from '../../../../shared/models/current-user';
import { CurrentUserService } from '../../../../shared/services/current-user-service';
import { GetAppResult } from '../../../../shared/models/apps/get-app-result.model';
import { RemoveItemComponent } from '../../../../shared/components/remove-item/remove-item.component';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { GridDataResult } from '@progress/kendo-angular-grid';

@Component({
    selector: 'list-apps',
    templateUrl: './list-apps.component.html',
    providers: [DataGridService],
    styleUrls: ['./list-apps.component.scss']
})
export class ListAppsComponent extends GridComponent<AppOverviewModel> implements OnInit {
    @ViewChild(RemoveItemComponent) removeItemModal: RemoveItemComponent;
    constructor(readonly dataGridService: DataGridService,
        readonly dataService: DataService,
        readonly alertService: AlertService,
        private readonly route: ActivatedRoute,
        readonly currentUserService: CurrentUserService) {
        super(dataGridService, alertService);
    }
    public sort: SortDescriptor[] = [{
        field: 'name',
        dir: 'asc'
      }];
      public gridView: GridDataResult = {
        data: [],
        total: 0
      };
    data: any;
    clientId: any;
    apps: any;

    loadApps() {
        this.currentUserService.getUserInfo().subscribe(data => {
            let userRoles = { userName: data.emailId };
            this.dataService.post<GetAppResult>(UrlPath.getApps(""), userRoles).subscribe(data => {
                console.log(data);
                this.data = data.paidApps.concat(data.offeredApps.concat(data.notActivatedApps.concat(data.invisibleApps)));
                this.gridView = {
                    data: orderBy(this.data, this.sort),
                    total: orderBy(this.data, this.sort).length
                  } 
            });
        });

    }

    public sortChange(sort: SortDescriptor[]): void {    
        this.sort = sort;
        console.log(sort);
        this.gridView = {
          data: orderBy(this.data, this.sort),
          total: this.data.length
        } 
        console.log(this.gridView);

      }

    ngOnInit() {
        //this.readUrl = UrlPath.admin_appApi;
        //this.putUrl = UrlPath.admin_appApi;
       // this.read();
        this.loadApps();
    }

    protected New(): AppOverviewModel {
        var model = new AppOverviewModel();
        return model;
    }

    appLink(app: AppOverviewModel): string {
        return UrlPath.app(app.AppId);
    }

    addNewAppLink(): string {
        return UrlPath.admin_apps_addNewApp;
    }

    editAppLink(appId: string): string {
        return UrlPath.admin_apps_editApp(appId);
    }

    manageDocuments(appId:string):string{
        return UrlPath.editArchiveAppDocument(appId);
    }


    remove(app: any) {
        this.removeUrl = UrlPath.admin_appApi_deleteApp(app.appId);
        this.dataGridService.loading = true;
       
        this.dataService.delete(this.removeUrl).subscribe(t=> {
            this.alertService.success('Operation successful','Deleted successfully')
            this.loadApps();
        });
    }

    showDeleteContentModal(content: any) {
        this.removeItemModal.entity = content;       
        this.removeItemModal.entityName = `"${content.name}"`;
        this.removeItemModal.setActive(true);
    }
}