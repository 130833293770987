﻿/// <reference path="../../app-routing.module.ts" />
export class RoutePath {
    static home = 'Index';
    static company = 'Company'; 
    static establishment = 'Establishment';
    static reportingCountry = 'ReportingCountry';
    static adminMenu = 'menu';
    static admin = 'admin'; 
    static adminApps = 'apps';
    static adminIndustries = 'admin/industries';
    static adminClients = 'admin/clients';
    static adminLegalEntities = 'legal-entity';
    static adminVatCountries = 'vat-countries';
    static adminNews = 'news';
    static manageArchiveAppDocuments = 'manage-archiveApps';
    static adminArchiveAppDocument = 'admin/apps/archiveappdocument';
    static surveyOverview = 'survey-overview';
    static survey = 'survey';
    static archiveAppDocuments = 'archiveappdocuments';
    static appPermission = 'AppPermission';
    static factsheetImportExport = 'FactsheetImportExport';
    static internalLogin = 'internal-login';
}