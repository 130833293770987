import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { MenuItemModel } from '../../../shared/models/menu-item';
import { DataService } from '../../../shared/services/data.service';
import { UrlPath } from '../../../shared/common/url-path';
import { Observable } from 'rxjs';
import { MenuService } from '../services/menu-service.service';
import { ContextMenuComponent } from '@progress/kendo-angular-menu';

@Component({
    selector: 'menu-tree',
    templateUrl: './menu-tree.component.html',
    styleUrls: ['./menu.component.scss']
})
export class MenuTreeComponent implements OnInit {

    contextMenuItems: any[] = [];
    @ViewChild('treemenu')
    public gridContextMenu: ContextMenuComponent;
    addChildMenuItem: any = {
        text: 'Add child'
    };
    removeMenuItem: any = {
        disabled: true,
        text: 'Remove'
    };

    menuItems: Observable<MenuItemModel[]>;
    constructor(readonly dataService: DataService, readonly menuService: MenuService) {
        this.menuItems = this.dataService.get<MenuItemModel[]>(UrlPath.getMenus(""));
    }

    ngOnInit() {
        this.contextMenuItems.push(this.addChildMenuItem);
    }
    public loadMenus() {
        this.menuItems = this.dataService.get<MenuItemModel[]>(UrlPath.getMenus(""));
    }
    @Output() menuSelected = new EventEmitter<MenuItemModel>();
    private contextItem: any;
    public onNodeClick(e: any): void {
        if (e.type === 'contextmenu') {
            const originalEvent = e.originalEvent;

            originalEvent.preventDefault();

            this.contextItem = e.item.dataItem;

            this.gridContextMenu.show({ left: originalEvent.pageX, top: originalEvent.pageY });
        }
    }

    handleSelection(selectedMenu: any) {
        this.menuService.formText = "Edit Menu";
        this.menuService.parentMenuItemId = selectedMenu.dataItem.ParentMenuItemId;
        this.menuSelected.emit(selectedMenu.dataItem);
    }
    onContextMenuClick(selectedMenu: any) {
        switch (selectedMenu.item.text) {
            case this.addChildMenuItem.text:
                this.menuService.formText = "Add Child";
                var menuItem = new MenuItemModel();
                this.menuService.parentMenuItemId = this.contextItem.MenuItemId;
                this.menuSelected.emit(menuItem);
                break;
        }
    }

}
