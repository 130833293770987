import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FileInfo, FileRestrictions } from '@progress/kendo-angular-upload';
@Component({
  selector: 'file-upload',
  templateUrl: './file-upload.component.html',
  styles: []
})
export class FileUploadComponent implements OnInit {

  constructor() { }
  @Output() fileUploaded = new EventEmitter<any>();
  @Output() loading = new EventEmitter<boolean>();
  remove: string;
  @Input() alowedTypes:any;

  ngOnInit() {

  }
  @Input() uploadSaveUrl: any;
  @Input()files: Array<FileInfo>;
  onUploadSuccess(e: any) {
    this.loading.emit(false);
    var uploadResoponese = {
      isSuccessfule: true,
      fileEntity: e.response.body
    };
    this.fileUploaded.emit(uploadResoponese);

  }

  public loadExistingFiles(files:any)
  {
    this.files=files;
  }

  public clear() {
    this.files = [];
  }

  //TODO: Replace this with Alert service
  errorEventHandler(e: ErrorEvent) {
  }


  
}
