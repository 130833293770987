﻿<div class="panel">
    <div class="panel-body">
        <div class="row hr-after">
            <div class="col-xs-12 font-size-xs text-center color-green">
                <p>Internal login(only for performance testing)</p>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12 padding-top-md">
                <span class="pull-right">Email id</span>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12 padding-top-md">
                <input type="text" class="full-width" #mail/>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 padding-top-md">
                <button class="btn btn-sm btn-secondary border-radius-round" (click)="internalLogin(mail.value)">Login</button>
            </div>
        </div>
    </div>
</div>