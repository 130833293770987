﻿import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { DataService } from "../../../../shared/services/data.service";
import { UrlPath } from "../../../../shared/common/url-path";
import { EditLegalEntityModel } from "../../../../shared/models/legal-entities/edit-legal-entity.model";

@Component({
    selector: 'edit-legal-entity',
    templateUrl: './edit-legal-entity.component.html',
    providers: [DataService]
})
export class EditLegalEntityComponent implements OnInit {
    entity: EditLegalEntityModel;
    id: string;
    loading: boolean;
    constructor(private readonly dataService: DataService,
        private readonly route: ActivatedRoute) { }

    ngOnInit() {
        this.id = this.route.snapshot.paramMap.get('id');
        this.get();
    }

    get() {
        this.loading = true;
        this.dataService.get<EditLegalEntityModel>(UrlPath.legalEntitiesApi_getEditModel(this.id)).subscribe(model => {
            this.entity = model;
            this.loading = false;
        });
    }

    save() {
        this.dataService.put<boolean>(UrlPath.legalEntitiesApi_edit, this.entity).subscribe((success: boolean) => {
            if (success) {
                window.location.href = UrlPath.legalEntities(this.entity.ClientId);
            }
        });
    }
}