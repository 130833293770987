<div class="card" style="margin-left: 1rem;">
<div class="card-body p-4">
  <h3 class="card-title mb-4 pr-3" style="font-size: 1.2rem;">Menu items</h3>  
      <kendo-treeview 
          kendoTreeViewHierarchyBinding
          kendoTreeViewExpandable
          kendoTreeViewSelectable
          (selectionChange)="handleSelection($event)"
          [nodes]="menuItems | async"
          textField="name"
          childrenField="Children"
          (nodeClick)="onNodeClick($event)">
      </kendo-treeview>
  
  <kendo-contextmenu #treemenu  [items]="contextMenuItems" (select)="onContextMenuClick($event)"></kendo-contextmenu>
  
</div>
</div>