﻿<div style="padding-right: 3em; padding-left: 2.2em;">
    <h4 style="font-size: 1.5em; margin-bottom: 1em;">App Permissions Management</h4>
    <div *ngIf="data">
        <kendo-grid [data]="data" [scrollable]="'scrollable'" [resizable]="true" [loading]="false"
                    (dataStateChange)="dataGridService.dataStateChange($event)"
                    (edit)="editHandler($event)" (add)="addHandler()" (remove)="removeHandler($event.dataItem)">

            <ng-template kendoGridToolbarTemplate>
                <a class="btn btn-deloitte" [attr.href]="addAppPermission()">Add new</a>
            </ng-template>
            <kendo-grid-column field="Name" title="Name" width="200">
                <ng-template kendoGridCellTemplate let-dataItem>
                    {{dataItem.appName}}
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="Functionality" title="Functionality" width="200">
                <ng-template kendoGridCellTemplate let-dataItem>
                    {{dataItem.functionality}}
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="Jurisdiction" title="Jurisdiction" width="200">
                <ng-template kendoGridCellTemplate let-dataItem>
                    {{dataItem.jurisdictions}}
                </ng-template>
            </kendo-grid-column>
            
            
            <kendo-grid-command-column title="" width="250">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <a class="btn btn-deloitte" style="width: 60px;" [attr.href]="editAppPermission(dataItem.appFunctionalityId)">Edit</a>
                    <a class="btn btn-deloitte" style="background-color: #97999B; color: white; width: 60px;"
                       (click)="remove(dataItem.appFunctionalityId)">Delete</a>
                    
                </ng-template>
            </kendo-grid-command-column>
        </kendo-grid>
    </div>
</div>