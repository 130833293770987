﻿import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";

import { Observable } from "rxjs";
import 'rxjs/add/operator/map';
import { MenuItemModel } from "../models/menu-item";
import { UrlPath } from "../common/url-path";
@Injectable()
export class DataService {
    constructor(private readonly http: HttpClient) { }
    isAuthorized: boolean;
    menu: MenuItemModel[] = [];
    url: string = UrlPath.apiBasePath;
    userid: any;
    userName: string;
    Login(): Observable<any> {
        return this.get("/auth").map((result: any) => {
            this.userName = result.FirstName + " " + result.LastName;
            this.userid = result.UserId;
            this.isAuthorized = JSON.parse(result.IsAuthorized);
            var menus: MenuItemModel[] = JSON.parse(JSON.stringify(result.MenuItems));
            this.menu = menus;
        }
        );
    }

    private toHttpParams(params?: object): HttpParams {
        if (!params) { return null; }
        const options = new HttpParams();

        for (const key in params) {
            if (params.hasOwnProperty(key)) {
                options.append(key.toString(), params[key].toString());
            }
        }
        return options;
    }

    private toHttpFormDataParams(params?: object): string {
        if (!params) { return null; }
        const formBody: string[] = [];

        for (const key in params) {
            if (params.hasOwnProperty(key)) {
                const encodedKey = encodeURIComponent(key);
                const encodedValue = encodeURIComponent(params[key]);
                formBody.push(`${encodedKey}=${encodedValue}`);
            }
        }
        return formBody.join("&");
    }

    // Create
    post<T>(urlPath: string, body: object, params?: object): Observable<T> {
        return this.http.post<T>(urlPath, JSON.stringify(body),
            {
                params: this.toHttpParams(params),
                headers: new HttpHeaders({
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                })
            });
    }

    postFormData<T>(urlPath: string, body: object, params?: object): Observable<T> {
        return this.http.post<T>(urlPath, this.toHttpFormDataParams(body), {
            params: this.toHttpParams(params),
            headers: new HttpHeaders({
                "Content-Type": "application/x-www-form-urlencoded",
                "Accept": "application/json"
            })
        });
    }

    postFiles<T>(urlPath: string, fileList: FileList, params?: object): Observable<T> {
        const formData = new FormData();
        for (let i = 0; i < fileList.length; i++) {
            formData.append(`uploadFile_${i}`, fileList[i], fileList[i].name);
        }
        return this.http.post<T>(urlPath, formData, {
            params: this.toHttpParams(params),
            headers: new HttpHeaders()
        });
    }

    // Read
    get<T>(urlPath: string, params?: object): Observable<T> {
        return this.http.get<T>(urlPath, {
            params: this.toHttpParams(params)
        });
    }

    // Update
    put<T>(urlPath: string, body: object, params?: object): Observable<T> {
        return this.http.put<T>(urlPath, JSON.stringify(body), {
            params: this.toHttpParams(params),
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        });
    }

    // Delete
    delete<T>(urlPath: string, params?: object): Observable<T> {
        return this.http.delete<T>(urlPath, {
            params: this.toHttpParams(params),
            headers: new HttpHeaders()
        });
    }
}