﻿import { Component, OnInit } from '@angular/core';
import { LegalEntityDetailModel} from "../../models/legal-entity-details.model"
import { JurisdictionAndTopicModel, JurisdictionModel} from '../../../shared/models/knowledgehub/Jurisdiction-topic.model';
import { DataService } from "../../../shared/services/data.service";
import { UrlPath } from "../../../shared/common/url-path";
import { ActivatedRoute } from '@angular/router';
import { CurrentUserModel } from '../../../shared/models/current-user';
import { CurrentUserService } from '../../../shared/services/current-user-service';
import { IndustryDto } from '../../../shared/models/industries/industryDto.model';
import { SalesType } from '../../models/sales-type.model';
import { CompanyOverviewModel } from '../../../company/models/company-overview.model';
import { CompanyDetailsModel } from '../../../company/models/company-details.model';
import { AlertService } from '../../../shared/services/alert.service';

@Component({
    selector: 'legal-entity-details',
    templateUrl: './legal-entity-details.component.html',
    providers: [DataService]
})
export class LegalEntityDetailsComponent implements OnInit {    
    jurisdictions: JurisdictionModel[];
    industries: IndustryDto[];
    data: LegalEntityDetailModel;
    companies: CompanyDetailsModel[];
    salesTypes = SalesType.all;
    public checked: boolean = false;
    constructor(private readonly route: ActivatedRoute, private readonly dataService: DataService, private readonly currentUserService: CurrentUserService, readonly alertService: AlertService) { }

    ngOnInit() {
        let currentUser: CurrentUserModel;
        this.currentUserService.getUserInfo().subscribe(data => {
            currentUser = data;
            this.dataService.get<any>
            (UrlPath.getJurisdictions('')).subscribe(data => {
                this.jurisdictions = data.jurisdictions;
            });
            this.dataService.get<any>
            (UrlPath.getIndustries('')).subscribe(data => {
                this.industries = data;             
            });
            this.dataService.get<any>
            (UrlPath.companyApi_getAll()).subscribe(data => {
                this.companies = data;
            })
        });
        if (this.route.snapshot.paramMap.get('legalEntityId') == '' || this.route.snapshot.paramMap.get('legalEntityId') == "new") {
            this.data = new LegalEntityDetailModel();
        }
        else {
            this.loadLegalEntity(this.route.snapshot.paramMap.get('legalEntityId'));
        }     

    }
    loadLegalEntity(legalEntityId: string) {
        this.dataService.get<any>
            (UrlPath.legalEntityApi_getLegalEntityDetails(legalEntityId)).subscribe(data => {
                this.data = data;
                this.data.IBANCode = data.ibanCode;
                this.data.SWIFTBICCode = data.swiftbicCode;
                if(data.dateDissolution)
                this.data.dateDissolution = new Date(data.dateDissolution);
                if(data.dateOfIncorporation)
                this.data.dateOfIncorporation = new Date(data.dateOfIncorporation);
                console.log(this.data);
            });
    }

    public virtual: any = {
        itemHeight: 28
    };

    onSubmit() {
        this.data.companyId = this.data.companyId;
        this.dataService.post(UrlPath.legalEntityApi_SaveLegalEntityDetails(), this.data).subscribe(() => {
            this.alertService.success('Success', 'Operation successful'); 
            window.location.href = UrlPath.legalEntity;
            
        });
    }
}