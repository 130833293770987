﻿import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { DataService } from "../../../../shared/services/data.service";
import { UrlPath } from "../../../../shared/common/url-path";
import { AppCreationResult } from "../../../../shared/models/apps/new-app.model";
import { CreateLegalEntityModel } from "../../../../shared/models/legal-entities/create-legal-entity.model";
import { EditClientModel } from "../../../../shared/models/clients/edit-client.model";

@Component({
    selector: 'create-legal-entity',
    templateUrl: './create-legal-entity.component.html',
    providers: [DataService]
})
export class CreateLegalEntityComponent implements OnInit {
    clientId: string;
    client: EditClientModel;
    name: string;
    loading: boolean;

    constructor(private readonly dataService: DataService,
        private readonly route: ActivatedRoute) { }


    ngOnInit() {
        this.clientId = this.route.snapshot.paramMap.get('clientId');
        this.get();
    }

    get() {
        this.loading = true;
        this.dataService.get<EditClientModel>(UrlPath.clientsApi_getEditModel(this.clientId)).subscribe(model => {
            this.client = model;
            this.loading = false;
        });
    }

    create() {
        var model = <CreateLegalEntityModel>{
            ClientId: this.clientId,
            Name: this.name
        };

        this.dataService.post<AppCreationResult>(UrlPath.legalEntitiesApi_create, model).subscribe((result: AppCreationResult) => {
            window.location.href = UrlPath.legalEntities(this.clientId);
        });
    }
}