﻿export class NewAppModel {
    AppTypeId: string;
    Name: string;
    Description: string;
    AlternateUrl: string;
    Url: string;
    IsVisible: boolean;
    IsOffered: boolean;
    ThumbnailImage: any;
    IsWhiteRoles: any;
    IsGreyRoles: any;
}

export class AppCreationResult {
    appId: string;
}