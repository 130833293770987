﻿import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { DataService } from "../../../../shared/services/data.service";
import { UrlPath } from "../../../../shared/common/url-path";
import { EditAppModel } from "../../../../shared/models/apps/edit-app.model";
import { AppTypeModel } from '../../../../shared/models/apps/app-type.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NewAppModel, AppCreationResult } from '../../../../shared/models/apps/new-app.model';
import { AppOverviewModel } from '../../../../shared/models/apps/app-overview.model';
import { formatDate } from '@angular/common';
import { FileRestrictions, FileInfo } from '@progress/kendo-angular-upload';
import { FileUploadComponent } from 'app/shared/components/file-upload/file-upload.component';

@Component({
    selector: 'edit-app',
    templateUrl: './edit-app.component.html',
    providers: [DataService],
    styleUrls: ['../list-apps/list-apps.component.scss']

})

export class EditAppComponent implements OnInit {
    roles : any;
    app: any;
    appId: string;
    selectedAppType: AppTypeModel;
    appName: string;
    appDescription: string;
    appContent: string;
    appUrl: string;
    appTypes: AppTypeModel[];
    image: any;
    imageUrl: any;
    uploadSaveUrl: string;
    fileContent: any;
    archiveAppId: string;
    alowedTypes: FileRestrictions = {
        allowedExtensions: ['.jpg','.png','.jpeg']
    };
    existingFile: Array<FileInfo> = [

    ];
    constructor(private readonly dataService: DataService,
        private readonly route: ActivatedRoute) { }
    @ViewChild(FileUploadComponent) fileUploadComponent: FileUploadComponent;
    ngOnInit() {
        this.dataService.get<any>(UrlPath.roles_getRoles()).subscribe((data: any) => {
            this.roles = data;          
            console.log(this.roles);
        });
        this.getAppTypes();
        this.appId = this.route.snapshot.paramMap.get('appId');


        this.uploadSaveUrl = UrlPath.uploadFile;
    }



    appForm = new FormGroup({
        appType: new FormControl(''),
        appName: new FormControl(''),
        appDescription: new FormControl(''),
        appIsVisible: new FormControl(''),
        appIsOffered: new FormControl(''),
        appUrl: new FormControl(''),
        appAlternateUrl: new FormControl(''),
        appThumbnailImage: new FormControl(''),
        appIsWhiteRoles: new FormControl(''),
        appIsGreyRoles: new FormControl('')
    });

    getAppTypes() {
        this.dataService.get<AppTypeModel[]>(UrlPath.appTypeApi).subscribe((data: AppTypeModel[]) => {
            this.appTypes = data;
            if (this.appTypes && this.appTypes.length) {
                var archiveApp = this.appTypes.filter(t => t.name === "Archive App")[0];
                this.archiveAppId = archiveApp ? archiveApp.id : '';
            }
            this.getApp();
        });
    }

    fillForm() {  
        console.log(this.app);
        this.appForm.controls['appType'].setValue(this.app.appTypeId);
        this.appForm.controls['appName'].setValue(this.app.name);
        this.appForm.controls['appDescription'].setValue(this.app.description);
        this.appForm.controls['appIsVisible'].setValue(this.app.isVisible);
        this.appForm.controls['appIsOffered'].setValue(this.app.isOffered);
        this.appForm.controls['appUrl'].setValue(this.app.url);
        this.appForm.controls['appAlternateUrl'].setValue(this.app.alternateUrl);
        this.appForm.controls['appThumbnailImage'].setValue(this.app.thumbnailImage);
        this.appForm.controls['appIsWhiteRoles'].setValue(this.app.isWhiteRoles.split(';'));
        this.appForm.controls['appIsGreyRoles'].setValue(this.app.isGreyRoles.split(';'));
        if (this.app.thumbnailImage) {
            this.existingFile.push({ name: `${this.app.name}icon.jpg`, extension: "", size: 0, state: 1, uid: this.app.id });            
        }
      
    }

    getApp() {

        this.dataService.get<any>(UrlPath.admin_appApi_getEditModel(this.appId)).subscribe((model: any) => {
            this.app = model;          
            this.fillForm();
          
        });
    }

    


    onSelectFile(event: any) { // called each time file input changes
        if (event.target.files && event.target.files[0]) {
            var reader = new FileReader();
            this.imageUrl = event.target.files[0];

            reader.readAsDataURL(event.target.files[0]); // read file as data url

            reader.onload = (event: any) => { // called once readAsDataURL is completed
                this.image = event.target.result;
            }
        }
    }


    save() {
        this.app = {
            appTypeId: this.appForm.controls['appType'].value,
            description: this.appForm.controls['appDescription'].value,
            alternateUrl: this.appForm.controls['appAlternateUrl'].value,
            isOffered: this.appForm.controls['appIsOffered'].value,
            name: this.appForm.controls['appName'].value,
            url: this.appForm.controls['appUrl'].value,
            isvisible: this.appForm.controls['appIsVisible'].value,
            isWhiteRoles: this.appForm.controls['appIsWhiteRoles'].value.join(";"),
            isGreyRoles: this.appForm.controls['appIsGreyRoles'].value.join(";"),
            id: this.app.id,
            thumbnailImage: this.fileContent ? this.fileContent.content : null
        }
        this.dataService.put<AppCreationResult>(UrlPath.admin_appApi_editApp, this.app).subscribe((result: AppCreationResult) => {
            window.location.href = UrlPath.admin_apps;
        });
    }

    isUploaded(uploadResonse: any) {
        this.fileContent = uploadResonse.fileEntity;

    }
}