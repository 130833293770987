export class JurisdictionAndTopicModel
{
    jurisdictions:JurisdictionPermissionModel[];
    topics:TopicPermissionModel[];
}

export class JurisdictionPermissionModel{
    jurisdictionId:string;
    code:string;
    hasAccess:boolean;
}
export class TopicPermissionModel{
    topicId:string;
    topicName:string;
    topicKey:string;
    isSelected: boolean;  
    hasAccess: boolean;  
}
export class JurisdictionModel{
    jurisdictionId:string;
    name:string;
}
     
