﻿import { Component } from "@angular/core";

import { RoutePath } from "../../shared/common/route-path";
import { UrlPath } from "../../shared/common/url-path";
import { ImagePath } from "../../shared/common/image-path";
import { CurrentUserModel } from "../../shared/models/current-user";
import { CurrentUserService } from "../../shared/services/current-user-service";
import { DataService } from '../../shared/services/data.service';
import { interval } from "rxjs";
import { Router, NavigationEnd } from "@angular/router";

const timeIntevalSeconds = 7200;
@Component({
    selector: "itp-header",
    templateUrl: "./layout-header.component.html",
    styleUrls: ["./layout-header.component.scss"],
})
export class LayoutHeaderComponent {
    deloitteLogo = ImagePath.logoImage;
    count: number;
    routePath = RoutePath;
    urlPath = UrlPath;
    notMobile = true;
    userfirstname: string;
    myInterval = setInterval(()=> { this.logout() }, timeIntevalSeconds * 1000);
;

    constructor(private currentUserService: CurrentUserService, private dataService: DataService, private router: Router) {
        router.events.subscribe((val) => {
            if(val instanceof NavigationEnd == true){
                clearInterval(this.myInterval);
                this.myInterval = setInterval(()=> { this.logout() }, timeIntevalSeconds * 1000);
                console.log("reseted");
            }
        });
     }


    ngOnInit() {
        let currentUser: CurrentUserModel;
        this.currentUserService.getUserInfo().subscribe(data => {

            currentUser = data;
            this.userfirstname = currentUser.name;
            if (window.screen.width <= 480) { // 768px portrait
                this.notMobile = false;
            }
        });
    }

    
    async logout() {
        this.dataService.get<string>(UrlPath.getLogoutUrl).subscribe(data => {
            window.location.replace(data);
        })

    }

}