<div *ngIf="data" style="margin-left: 4rem; margin-right: 4em;">
    <h4 style="margin-bottom: 1em;  font-size: 1.5em;">Survey Permissions Management</h4>
<kendo-grid [kendoGridBinding]="data"
            [pageSize]="dataGridService.state.take"
            [skip]="dataGridService.state.skip"
            [sort]="dataGridService.state.sort"
            [sortable]="true"
            [pageable]="dataGridService.pageable"
            [groupable]="false"
            [scrollable]="'scrollable'"
            [filter]="dataGridService.state.filter"
            filterable="menu"
            [columnMenu]="true"
            [resizable]="true"
            [reorderable]="true"
            [loading]="false"
            (dataStateChange)="dataGridService.dataStateChange($event)"
            (edit)="editHandler($event)"
            (add)="addHandler()"
            (remove)="removeHandler($event.dataItem)">

    <ng-template kendoGridToolbarTemplate>
        <div *ngIf="canModifyPermissions">
            <a class="btn btn-deloitte" [attr.href]="addNewPermissionLink(dataItem)">Add new</a>
            <a class="btn btn-deloitte" [class.disabled]="isImportingBtnEnabled ? null : true" style="float: right; margin-left: 7px;" (click)="importDataFromITX()">Import data from ITX</a>
            <a class="btn btn-deloitte" [class.disabled]="isImportingBtnEnabled ? null : true" style="float: right; margin-left: 7px;" (click)="downlodDataFromITX()">Download data from ITX</a>
        </div>
    </ng-template>

    <kendo-grid-column field="topic.topicName" title="Topic"></kendo-grid-column>
    <kendo-grid-column field="countries" title="Countries"></kendo-grid-column>   
    <kendo-grid-command-column title="" width="150" *ngIf="canModifyPermissions">
        <ng-template kendoGridCellTemplate let-dataItem>
            <a class="btn btn-deloitte" [attr.href]="editPermissionLink(dataItem)"> Edit</a>
            <a class="btn btn-deloitte" style="background-color: #97999B; color: white; width: 60px;"(click)="remove(dataItem)">Delete</a>
        </ng-template>
    </kendo-grid-command-column>
</kendo-grid>

<!--<add-new-app [model]="editDataItem" [isNew]="isNew" (save)="saveHandler($event)" (cancel)="cancelHandler()"></add-new-app>-->
</div>