﻿import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';


@Component({
    selector: 'legal-entities-overview',
    templateUrl: './overview.component.html'
})
export class LegalEntitiesOverviewComponent implements OnInit {
    clientId: string;
    constructor(private readonly route: ActivatedRoute) { }

    ngOnInit() {
        this.clientId = this.route.snapshot.paramMap.get('clientId');
    }
}