﻿import { Component, OnInit } from '@angular/core';

import { GridComponent } from "../../../../shared/components/grid/grid.component";
import { DataGridService } from "../../../../shared/services/data-grid.service";
import { AlertService } from "../../../../shared/services/alert.service";

import { UrlPath } from "../../../../shared/common/url-path";
import { ClientOverviewModel } from "../../../../shared/models/clients/client-overview.model";

@Component({
    selector: 'list-clients',
    templateUrl: './list-clients.component.html',
    providers: [DataGridService]
})
export class ListClientsComponent extends GridComponent<ClientOverviewModel> implements OnInit {
    constructor(readonly dataGridService: DataGridService,
        readonly alertService: AlertService) {
        super(dataGridService, alertService);
    }

    ngOnInit() {
        this.readUrl = UrlPath.clientsApi;
        this.putUrl = UrlPath.clientsApi;
        this.read();
    }

    protected New(): ClientOverviewModel {
        var model = new ClientOverviewModel();
        return model;
    }

    appLink(model: ClientOverviewModel): string {
        return UrlPath.legalEntities(model.Id);
    }

    addNewAppLink(): string {
        return UrlPath.clients_create;
    }

    editAppLink(model: ClientOverviewModel): string {
        return UrlPath.clients_edit(model.Id);
    }

    remove(model: ClientOverviewModel) {
        this.removeUrl = UrlPath.clientsApi_delete(model.Id);
        this.dataGridService.loading = true;
        this.dataGridService.deleteUrlPath = this.removeUrl;
        this.dataGridService.removeById();
    }
}