<div class="card">
    <div class="card-body p-4">
        <div class="form-group">
            <h3 *ngIf="menuService.formText" class="card-title mb-4"  style="font-size: 1.2rem;">{{menuService.formText}}</h3>
            <h3 *ngIf="!menuService.formText" class="card-title mb-4" style="font-size: 1.2rem;">Add Menu</h3>
        </div>
        <form [formGroup]="menuForm" (ngSubmit)="onSubmit()">

            <div class="form-group">
                <label>Name:</label>
                <input type="text" class="form-control" formControlName="name" required>
            </div>
            <div class="form-group">
                <label>Url:</label>
                <input type="text" class="form-control" formControlName="url" required>
            </div>
            <div class="form-group">
                <label>View Order:</label>
                <input type="number" class="form-control" formControlName="ViewOrder" required>
            </div>
            <div class="form-group">
                <button type="submit" class="btn-deloitte btn" [disabled]="!menuForm.valid">Submit</button>
            </div>
        </form>
    </div>
</div>