﻿import { Component } from '@angular/core';
import { CurrentUserService } from '../shared/services/current-user-service';
import { CurrentUserModel } from '../shared/models/current-user';


@Component({
    templateUrl: './not-authorized.component.html'
})
export class NotAuthorizedComponent {    
    userInfo: CurrentUserModel;
    constructor(private userInfoService: CurrentUserService) { }
    async ngOnInit() {
        this.userInfo = await this.userInfoService.getUserInfo().toPromise() as CurrentUserModel;
    }
}

